import React, { useEffect, useRef } from "react";
import SubscribeSection from "../Homepage/SubscribeSection";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import showNotification from "../../services/NotificationService";
import { addContactB2B } from "../../services/Apiservices";

function CorporateGifting() {
  const [loading, setisLoading] = useState(false);
  const ref = useRef(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, []);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .required("Mobile number is required"),
    subject: Yup.string().required("About is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      subject: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleRegister(values);
    },
  });

  const handleRegister = async (values) => {
    setisLoading(true);
    let body = {
      business_name: values?.name,
      business_email: values?.email,
      business_mobile: values?.mobile,
      business_message: values?.subject,
    };
    try {
      let response = await addContactB2B(body);
      if (response?.status == 200) {
        setisLoading(false);
        showNotification("success", response?.data?.message);
        formik.handleReset();
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  const handleSroll = () => {
    ref.current?.scrollIntoView({behavior: 'smooth'});
      };
  return (
    <>
      <div className="main_content">
        {/* START SECTION SHOP */}
        <div
          style={{
            backgroundColor: "#fdceda",
            position: "relative",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            height: "30%",
            backgroundSize: "cover",
          }}
        >
          <div
            className="inner position-absolute top-50 start-50 p-2  text-center translate-middle fs-2 fw-bold"
            style={{ backgroundColor: "#fdceda", color: "black" }}
          >
            Get In Touch With Us.
            <p className="text-black" style={{ fontSize: "16px" }}>
              Get solution to your business needs directly, Fill form to contact
              with us.
            </p>
          
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1550"
            height="250"
            className="mx-auto"
            viewBox="0 0 1300 200"
            fill="none"
          >
            <path
              opacity=".2"
              d="M618.972 117.511 323.663-139.94 179.069-13.6729 470.729 239.454M618.972 117.511v-362.28H819.319v362.28m-200.347.0 99.441 86.693m0 0L1114.49-138.76 1255.32-13.8696 963.913 239.454m-245.5-35.25 142.82 124.511m0 0 102.68 89.516H1566V239.454H963.913m-102.68 89.261 102.68-89.261m-493.184.0L676.721 418.231H-176V239.454H470.729z"
              stroke="#ff5b77"
              stroke-width="5"
            />
          </svg>
        </div>
        <div className="my-5">
          <div className="container">
            <div className="row ">
              {/* <h2 className="text-center text-dark">
                CREDENCEREWARDS B2B GIFTING SOLUTIONS.
              </h2> */}
            </div>
            <div className="row mt-1">
              <div className="col-lg-6 col-sm-12 mt-1">
                <div className="heading_s1">
                  <h2>Supercharge your gifting and rewards</h2>
                </div>

                <p>
                  {" "}
                  With Credence-Rewards, Send something unexpected from our
                  catalog of incredible gifts, and more, creating
                  meaningful interactions that go beyond business as usual.
                </p>
                <p>
                  {" "}
                  Whether it's birthdays, anniversaries, festivals, corporate
                  events, or simply expressing appreciation, our gift cards are
                  the ideal choice for any celebration. Give the gift of choice
                  and let your loved ones pick what brings them joy.
                </p>
              </div>
              <div style={{ width: "50%" }} className=" col-lg-6 col-sm-12" >
                <div className="heading_s1" ref={ref}>
                  {/* <h2 style={{ color: "#FF324D" }}>Get In touch with us.</h2> */}
                </div>
                {/* <p className="leads fs-5 fw-semibold">
                  Get solution to your business needs directly, Fill form to
                  contact with us.
                </p> */}
                <div className="field_form " >
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6 mb-3" >
                        <input
                          className="form-control"
                          maxLength={80}
                          name="name"
                          defaultValue=""
                          placeholder="Name or Business Name"
                          id="business_name"
                          autoComplete="off"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <input
                          className="form-control"
                          maxLength={70}
                          name="email"
                          defaultValue=""
                          placeholder="Email"
                          id="business_email"
                          autoComplete="off"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <input
                          className="form-control"
                          minLength={10}
                          maxLength={14}
                          name="mobile"
                          defaultValue=""
                          placeholder="Mobile with Country Code"
                          id="business_mobile"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3" >
                        <textarea
                          className="form-control"
                          required=""
                          style={{ height: 100 }}
                          cols={50}
                          charswidth={23}
                          name="subject"
                          id="business_message"
                          autoComplete="off"
                          type="text"
                          rows={8}
                          placeholder="Something About your Business..."
                          defaultValue={""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject}
                        />
                        {formik.touched.subject && formik.errors.subject ? (
                          <div className="text-danger">
                            {formik.errors.subject}
                          </div>
                        ) : null}
                      </div>
                   
                      <div className="col-md-12  d-flex align-items-center justify-content-end">
                        {loading ? (
                          <button
                            type="button"
                            title="Submit Your Message!"
                            className="btn btn-fill-out"
                            name="submit"
                          >
                            Processing...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            title="Submit Your Message!"
                            className="btn btn-fill-out"
                            name="submit"
                            value="Submit"
                          >
                            Submit
                          </button>
                        )}
                      
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              {/* <div className="section bg_light_blue2 pb_70">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="heading_s1 text-center">
                  <h2>Why Choose Us?</h2>
                </div>
                <p className="text-center leads">
                  For the past 7 years, At Credence Rewards, we take immense
                  pride in being your trusted one-stop destination for a diverse
                  selection of gift cards from multiple renowned brands.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-pencil-alt" />
                  </div>
                  <div className="icon_box_content">
                    <h5>Vast Range of Gift Card</h5>
                    <p>
                      With a vast assortment of gift cards from multiple brands,
                      we offer a world of options to suit every occasion and
                      celebration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-layers" />
                  </div>
                  <div className="icon_box_content">
                    <h5>Instant Delivery</h5>
                    <p>
                      We understand the importance of timely gifting, and that's
                      why we offer instant delivery of e-gift cards through
                      email and other sources.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-email" />
                  </div>
                  <div className="icon_box_content">
                    <h5>No Hidden Charges</h5>
                    <p>
                      Transparency is at the core of our values. When you choose
                      us, you can rest assured that there are no hidden charges
                      or additional fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
            </div>
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        {/* <SubscribeSection /> */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
    </>
  );
}

export default CorporateGifting;
