import React, { useEffect, useState } from "react";
import SubscribeSection from "../Homepage/SubscribeSection";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllOrders,
  getProfile,
  upDateProfile,
} from "../../services/Apiservices";
import { logout } from "../../redux/reducers/authSlice";
import { setUser } from "../../redux/reducers/userSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import showNotification from "../../services/NotificationService";
import { BASE_URL } from "../../constants";

function Account() {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm("Are you sure you want to logout?");

    if (confirmLogout) {
      dispatch(logout());
      navigate("/");
      localStorage.clear();
    }
  };

  const [orderList, setOrderList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isloading, setisLoading] = useState(false);

  useEffect(() => {
    getOrders();
    getProfileApi();
  }, []);

  const getOrders = async () => {
    setLoading(true);
    try {
      let response = await getAllOrders();
      if (response?.status == 200) {
        setOrderList(response?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setOrderList(null);
    }
  };

  const handleShowStatus = (key) => {
    switch (key) {
      case 1:
        return "Not Started";
      case 2:
        return "Processing";
      case 3:
        return "Completed";
      case 4:
        return "Failed";
      case 5:
        return "Incompleted";

      default:
        return "N/A";
    }
  };
  const getProfileApi = async () => {
    try {
      let response = await getProfile();
      if (response?.status == 200) {
        dispatch(setUser(response?.data?.data));
      }
    } catch (error) {}
  };
  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      full_name: user?.full_name,
      dob: user?.dob,
      gst_no: user?.gst_no,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      // handleRegister(values)
      handleUpdate(values);
    },
  });
  const handleUpdate = async (values) => {
    setisLoading(true);
    let body = {
      full_name: values?.full_name,
      dob: values?.dob,
      gst_no: values?.gst_no,
      profile_pic: values?.file,
    };
    const formData = new FormData();

    for (const key in body) {
      if (body.hasOwnProperty(key) && body[key] !== undefined) {
        formData.append(key, body[key]);
      }
    }
    try {
      let response = await upDateProfile(formData);
      if (response?.status == 200) {
        showNotification("success", "Profile updated successfully");
        setisLoading(false);

        getProfileApi();
        document.getElementById("new").click();
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  return (
    <>
      {/* START MAIN CONTENT */}
      <div className="main_content mt-3">
        <div className="section py-1 my-1" style={{ minHeight: "60vh" }}>
          <div className="container">
            {/* <div className="text-center alert alert-info alert-dismissible p-1">
          <a className="close" data-dismiss="alert" aria-label="close">
            ×
          </a>
          <small>
            {"{"}
            {"{"} message|safe {"}"}
            {"}"}
          </small>
        </div> */}

            {/* START SECTION SHOP */}
            <div className="section py-5 my-5" style={{ minHeight: "60vh" }}>
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-3 col-md-4">
                    <div className="dashboard_menu">
                      <ul className="nav nav-tabs flex-column" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="dashboard-tab"
                            data-bs-toggle="tab"
                            href="#dashboard"
                            role="tab"
                            aria-controls="dashboard"
                            aria-selected="false"
                          >
                            <i className="ti-user" />
                            My Profile
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            id="orders-tab"
                            data-bs-toggle="tab"
                            href="#orders"
                            role="tab"
                            aria-controls="orders"
                            aria-selected="false"
                          >
                            <i className="ti-shopping-cart-full" />
                            Orders
                          </a>
                        </li>
                 
                      </ul>
                    </div>
                  </div> */}
                  <div className="col-lg-12 col-md-12">
                    <div className="tab-content dashboard_content">
                      <div
                        className="tab-pane fade active show"
                        id="dashboard"
                        role="tabpanel"
                        aria-labelledby="dashboard-tab"
                      >
                        <div className="card">
                          <div className="card-header">
                            <h3>My Profile</h3>
                          </div>
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-lg-4">
                                <div className="about_img scene mb-4 mb-lg-0">
                                  <img
                                    src={
                                      user?.profile_picture
                                        ? BASE_URL + user?.profile_picture
                                        : "../assets/imgs/default-dp.jpg"
                                    }
                                    alt="about_img"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-8">
                                <div className="heading_s1 text-center">
                                  <h2>{user?.full_name}</h2>
                                </div>
                                <div className="row my-3">
                                  <div className="col-4">Name</div>
                                  <div className="col-8">{user?.full_name}</div>
                                </div>
                                <div className="row  my-3">
                                  <div className="col-4">Email</div>
                                  <div className="col-8">{user?.email}</div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-4">Mobile No</div>
                                  <div className="col-8">{user?.mobile_no}</div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-4">GST No.</div>
                                  <div className="col-8">
                                    {user?.gst_no ? user?.gst_no : "--"}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-4">Date of Birth</div>
                                  <div className="col-8">
                                    {user?.dob ? user?.dob : "None"}
                                  </div>
                                </div>
                                <div className="row my-3">
                                  <div className="col-lg-12 mt-5 text-center">
                                    <button
                                      type="button"
                                      className="btn btn-fill-out edit-profile-btn"
                                      data-toggle="modal"
                                      data-target="#edit-profile-modal"
                                    >
                                      Edit Profile{" "}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="orders"
                        role="tabpanel"
                        aria-labelledby="orders-tab"
                      >
                        <div className="card">
                          <div className="card-header">
                            <h3>Orders</h3>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>
                                      <small>Card Value</small>
                                    </th>
                                    <th>Qnty</th>
                                    <th>Total</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                {orderList?.results?.length !== 0 ? (
                                  <>
                                    <tbody>
                                      {orderList?.results
                                        ?.slice(0, 5)
                                        ?.map((item, index) => {
                                          return (
                                            <tr key={index}>
                                              <td className="text-success">
                                                #cr_00{item?.id}
                                              </td>

                                              <td>
                                                <small>
                                                  {item?.created_on}
                                                </small>
                                              </td>
                                              <td>
                                                <span className="">
                                                  <small>
                                                    {handleShowStatus(
                                                      item?.order_status
                                                    )}
                                                  </small>
                                                </span>
                                              </td>

                                              <td className="price text-dark">
                                                ₹ {item?.denomination}
                                              </td>
                                              <td>{item?.quantity}</td>
                                              <td className="price">
                                                ₹{" "}
                                                {item?.denomination *
                                                  item?.quantity}{" "}
                                                <small></small>
                                              </td>
                                              {/* <td>
                                                <Link
                                                  to={`/wallet/order/${item?.id}/details`}
                                                  className="btn btn-fill-out btn-sm"
                                                >
                                                  View
                                                </Link>
                                              </td> */}
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </table>
                            </div>
                            <div className="row my-3">
                              <div className="col-lg-12 mt-1 text-center">
                                <Link
                                  to="/wallet/my-orders"
                                  className="btn btn-fill-out"
                                >
                                  All Orders
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="crorders"
                        role="tabpanel"
                        aria-labelledby="cr-orders-tab"
                      >
                        <div className="card">
                          <div className="card-header">
                            <h3>Credence Code Reedemption</h3>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              {"{"}% for order in cr_orders %{"}"}
                              {"{"}% endfor %{"}"}
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Order</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>
                                      <small>Card Value</small>
                                    </th>
                                    <th>Brand</th>
                                    <th>Actions</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="text-success">
                                      #cr_10{"{"}
                                      {"{"} order.id {"}"}
                                      {"}"}
                                    </td>
                                    <td>
                                      <small>
                                        {"{"}
                                        {"{"} order.created_on {"}"}
                                        {"}"}
                                      </small>
                                    </td>
                                    <td>
                                      {"{"}% if order.status == 1 or
                                      order.status == "1" %{"}"}
                                      <span className="bg-success text-white rounded-2 p-1">
                                        <small>Success</small>
                                      </span>
                                      {"{"}% else %{"}"}
                                      <span className="bg-warning text-danger rounded-2 p-1">
                                        <small>Failed</small>
                                      </span>
                                      {"{"}% endif %{"}"}
                                    </td>
                                    <td className="price text-dark">
                                      ₹ {"{"}
                                      {
                                        "{"
                                      } order.amount|default_if_none:"N/A" {"}"}
                                      {"}"}
                                    </td>
                                    <td>
                                      {"{"}
                                      {"{"} order.provider_name {"}"}
                                      {"}"}
                                    </td>
                                    <td>
                                      <a
                                        href="#"
                                        className="btn btn-fill-out btn-sm"
                                      >
                                        View
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="row my-3">
                              <div className="col-lg-12 mt-1 text-center">
                                <Link
                                  to="/cr/my-redeem-orders"
                                  className="btn btn-fill-out"
                                >
                                  All Orders
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="address"
                        role="tabpanel"
                        aria-labelledby="address-tab"
                      >
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="card mb-3 mb-lg-0">
                              <div className="card-header">
                                <h3>Billing Address</h3>
                              </div>
                              <div className="card-body">
                                <div className="row px-1">
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      Full Address :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.address {"}"}
                                    {"}"}
                                  </div>
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      {" "}
                                      City :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.city {"}"}
                                    {"}"}
                                  </div>
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      {" "}
                                      State :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.state {"}"}
                                    {"}"}
                                  </div>
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      {" "}
                                      District :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.district {"}"}
                                    {"}"}
                                  </div>
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      {" "}
                                      Pin Code :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.pin_code {"}"}
                                    {"}"}
                                  </div>
                                  <div className="col-4 py-1">
                                    <span className="price text-dark">
                                      {" "}
                                      Country :
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    {"{"}
                                    {"{"} users_address.country {"}"}
                                    {"}"}
                                  </div>
                                </div>
                                <div className="col-12 text-center py-3">
                                  <a href="#" className="btn btn-fill-out">
                                    Edit
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* END SECTION SHOP */}
            {/* START SECTION SUBSCRIBE NEWSLETTER */}
            <SubscribeSection />
            {/* START SECTION SUBSCRIBE NEWSLETTER */}
          </div>
          {/* END MAIN CONTENT */}
          {/* Modal */}
          <div
            className="modal fade subscribe_popup"
            data-backdrop="static"
            data-keyboard="false"
            id="edit-profile-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="edit-profile-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="new"
                  >
                    <span aria-hidden="true">
                      <i className="ion-ios-close-empty" />
                    </span>
                  </button>
                  <div className="row g-0">
                    <div className="card">
                      <div className="card-header">
                        <h3>Update Profile</h3>
                      </div>
                      <div className="card-body">
                        <p>Update your personal profile</p>
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="form-group col-md-12 mb-3">
                              <label>Full Name </label>
                              <input
                                type="text"
                                name="full_name"
                                placeholder="Full Name"
                                required=""
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.full_name}
                              />
                              {formik.touched.full_name &&
                              formik.errors.full_name ? (
                                <div className="text-danger">
                                  {formik.errors.full_name}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-group col-md-12 mb-3">
                              <label>Date Of Birth </label>
                              <input
                                type="date"
                                name="dob"
                                min="1980-01-01"
                                max={new Date()?.toISOString()?.split("T")[0]}
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.dob}
                              />
                            </div>
                            <div className="form-group col-md-12 mb-3">
                              <label>GST No. </label>
                              <input
                                maxLength={20}
                                name="gst_no"
                                placeholder="Gst No."
                                id="gst_no"
                                autoComplete="off"
                                type="text"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.gst_no}
                              />
                            </div>
                            <div className="form-group col-md-12 mb-3">
                              <label>Profile Picture </label>
                              <input
                                type="file"
                                name="profile"
                                accept="image/*"
                                className="form-control"
                                onChange={(event) => {
                                  formik.setFieldValue(
                                    "file",
                                    event.target.files[0]
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.profile}
                              />
                            </div>
                            <div className="col-md-12">
                              <button
                                type="submit"
                                className="btn btn-fill-out"
                                name="submit"
                                value="Submit"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* modal end */}

          {/* 
    
     */}
        </div>
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default Account;
