import React, { useEffect } from 'react'

function Faqs() {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This provides a smooth scroll effect
          });
    },[])
  return (
    <>

  <div className="section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="heading_s1 mb-3 mb-md-5">
            <h3>FAQ's</h3>
          </div>
          <div id="accordion" className="accordion accordion_style1">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    What is Credencerewards.com?
                  </a>
                </h6>
              </div>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordion"
                style={{}}
              >
                <div className="card-body">
                  <p>
                    CredenceRewards.com is a service that offers a fun and
                    convenient way to send personalized eGift Cards from popular
                    stores,brand, directly from your computer or smartphone to
                    your friends and family. The eGift Cards are sent by email
                    or SMS and can also be printed if you wish to personally
                    hand-deliver.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    What services does CredenceRewards.com offer?
                  </a>
                </h6>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  We offer four types of gifting services:
                  <ul className="list_style_3">
                    <li>
                      Personal Gifting: Send an eGift Card from popular stores
                      to friends and family for any occasion by email, SMS, or
                      print at home.
                    </li>
                    <li>
                      Corporate Gifting: Designed specifically for organizations
                      that want to reward their employees or customers with an
                      eGift Card. You can instantly contact us at
                      CredenceRewards.com, or if you have any inquiries, please
                      write us at support@credencerewards.com
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFour">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    How do I purchase an eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>Four simple steps:</p>
                  <ul className="list_style1">
                    <li>
                      Select the eGift Card you’d like to gift from the
                      convenience of your smartphone, computer, or tablet.
                    </li>
                    <li>
                      Personalize your eGift Card with a greeting, message, and
                      photo or video.
                    </li>
                    <li>
                      Select your preferred delivery method (email, SMS, or
                      print at home).
                    </li>
                    <li>
                      We accept Visa, MasterCard, AMEX and mada on website.
                      Additionally, you can pay , All payment methods in one
                      Gateway that is India's secure and safe method - Razorpay.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading5">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapse5"
                  >
                    Do you have a mobile app?
                  </a>
                </h6>
              </div>
              <div
                id="collapse5"
                className="collapse"
                aria-labelledby="heading5"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Yes, we do. You can download it from the App Store or Google
                    Play.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading6">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapse6"
                  >
                    How will my eGift Card be delivered?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse6"
                className="collapse"
                aria-labelledby="heading6"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    There are three ways to send an eGift Card. For instant or
                    scheduled delivery, you can choose to send it to the gift
                    receiver by email and SMS. Alternatively, you can print the
                    eGift Card at home and hand it to the receiver yourself.
                    Please note that we do not deliver physical Gift Cards.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading7">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapse7"
                  >
                    How long is the eGift Card valid for?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse7"
                className="collapse"
                aria-labelledby="heading7"
                data-bs-parent="#accordion"
                style={{}}
              >
                <div className="card-body">
                  <p>
                    E-GIft Card: Mostly E-Gft cards have Valid for 12 months
                    from the date of purchase.
                    <br />
                    Some store-specific eGift Cards: The eGift Card validity
                    varies across different brands, and is specified in the
                    ‘Redemption Details’ box located on the eGift Card sent to
                    your email or you can check on
                    CredenceRewards.Com/balance-checker Also.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading8">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapse8"
                  >
                    Can I personalize my eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse8"
                className="collapse"
                aria-labelledby="heading8"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Absolutely. That’s the beauty of our service! We have a
                    variety of greetings and designs to choose from for any
                    occasion, and you can include a personal message and photo
                    or a video as well.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading9">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapse9"
                  >
                    How do I order a Gift Card or an E-Gift Card?
                  </a>
                </h6>
              </div>
              <div
                id="collapse9"
                className="collapse"
                aria-labelledby="heading9"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Ordering a Gift Card or an E-Gift Card through
                    CredenceRewards.com is easy. Once you select your favourite
                    brand, select the card type. You can choose from Gift Card,
                    E-Gift Card or Gift Voucher. Enter the denomination and
                    quantity you wish to purchase. Click on the "Buy now" button
                    and you will be directed to the Checkout page. In the
                    Checkout page you just need to enter the billing, shipping
                    details and choose the suitable payment mode to complete
                    your purchase.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading10">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse10"
                    aria-expanded="false"
                    aria-controls="collapse10"
                  >
                    How do I redeem my eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse10"
                className="collapse"
                aria-labelledby="heading10"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Usage of a Gift Card or an E-Gift Card is dependent on the
                    brand's Terms and Conditions. Gift Cards are accepted in all
                    the participating retail outlets of a particular brand. Any
                    limitations or special cases will be specified in the 'Terms
                    &amp; Conditions' tab on the particular Gift Card.
                    <br />
                    GO to selected brands Website/ App and read more about that
                    voucher gift card.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading11">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapse11"
                  >
                    Where can I redeem my eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse11"
                className="collapse"
                aria-labelledby="heading11"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Please refer to the ‘Redemption Details’ section located on
                    your eGift Card.
                    <br />
                    Other store-specific eGift Cards: Please refer to the ‘Store
                    Locations’ section located on your eGift Card.
                    <br />
                    GO to selected brands Website/ App and read more about that
                    voucher gift card.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mt-4 mt-md-0">
          <div className="heading_s1 mb-3 mb-md-5">
            <h3>Other questions</h3>
          </div>
          <div id="accordion2" className="accordion accordion_style1">
            <div className="card">
              <div className="card-header" id="headingSix">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    What can I purchase with my eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseSix"
                className="collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordion2"
                style={{}}
              >
                <div className="card-body">
                  <p>
                    The services is described by Store and Brand of gift card
                    that you have to choose. Please refer to terms and condition
                    for more details. Ordering a Gift Card or an E-Gift Card
                    through CredenceRewards.com is easy. Once you select your
                    favourite brand, select the card type. You can choose from
                    Gift Card, E-Gift Card or Gift Voucher. Enter the
                    denomination and quantity you wish to purchase. Click on the
                    "Buy now" button and you will be directed to the Checkout
                    page. In the Checkout page you just need to enter the
                    billing, shipping details and choose the suitable payment
                    mode to complete your purchase.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingSeven">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Where are these Gift Cards accepted?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseSeven"
                className="collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    Usage of a Gift Card or an E-Gift Card is dependent on the
                    brand's Terms and Conditions. Gift Cards are accepted in all
                    the participating retail outlets of a particular brand. Any
                    limitations or special cases will be specified in the 'Terms
                    &amp; Conditions' tab on the particular Gift Card.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingEight">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Can I exchange my eGift Card for its value in cash?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseEight"
                className="collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    No. You can only redeem it against merchandise or services
                    from the brand(s) listed on the eGift Card.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingNine">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Can I use my eGift Card online?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseNine"
                className="collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    Please refer to the ‘Redemption Details’ section located on
                    your eGift Card.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTen">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    Can I use my eGift Card during offers/promotions?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseTen"
                className="collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    Please refer to the ‘Redemption Details’ section located on
                    your eGift Card.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headinga1">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapsea1"
                    aria-expanded="false"
                    aria-controls="collapsea1"
                  >
                    How do I check the validity of my eGift Card?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapsea1"
                className="collapse"
                aria-labelledby="headinga1"
                data-bs-parent="#accordion2"
                style={{}}
              >
                <div className="card-body">
                  <p>
                    CredenceRewrads Balance Checker: You can find the expiry
                    date of your eGift Card from credencerewards balance
                    Checker.
                    <br />
                    Mobile App: You can find the expiry date of your eGift Card
                    in the ‘Wallet’ inside the Credence Rewards mobile app.
                    <br />
                    Website: You can find the expiry date of your eGift Card in
                    the ‘Wallet’ inside the Credence Rewards account.
                    <br />
                    Email/Print: The eGift Card validity varies across different
                    brands, and is specified in the email or printed
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headinga2">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapsea2"
                    aria-expanded="false"
                    aria-controls="collapsea2"
                  >
                    What payment methods do you accept?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapsea2"
                className="collapse"
                aria-labelledby="headinga2"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    We accept Visa, MasterCard, AMEX and mada on website.
                    Additionally, All methods are integrated in one method i.e
                    India's safe and secure payment method Razorpay.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headinga3">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapsea3"
                    aria-expanded="false"
                    aria-controls="collapsea3"
                  >
                    Can I get a refund if I change my mind on the purchase?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapsea3"
                className="collapse"
                aria-labelledby="headinga3"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    All purchases are non-refundable nor exchangeable, as per
                    the store's eGift Card policy.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headinga4">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapsea4"
                    aria-expanded="false"
                    aria-controls="collapsea4"
                  >
                    Are there any hidden charges or taxes?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapsea4"
                className="collapse"
                aria-labelledby="headinga4"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    At credencerewards.com, you pay the price that's mentioned
                    in the checkout page – that's all.
                    <br />
                    There are absolutely no hidden charges or taxes and what you
                    get is what you see.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headinga5">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapsea5"
                    aria-expanded="false"
                    aria-controls="collapsea5"
                  >
                    Will I receive a confirmation after placing an order?
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapsea5"
                className="collapse"
                aria-labelledby="headinga5"
                data-bs-parent="#accordion2"
              >
                <div className="card-body">
                  <p>
                    Yes, you will receive the confirmation through E-mail &amp;
                    also via SMS.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <hr />
  <div className="section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="">
            <h2>Why from CredenceRewards.in ?</h2>
          </div>
          <p>
            Why Gift cards from CredenceRewards.com are the best gifting option
            for your loved ones?
            <span className="bg-danger text-white">
              -Has Experience of about 7 years.
            </span>
          </p>
        </div>
      </div>
      <hr />
      <div className="row align-items-center">
        <div className="col-lg-12">
          <div className="">
            <h2>
              Why Gift cards from CredenceRewards.com are the best gifting
              option for your loved ones?
            </h2>
          </div>
          <p></p>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="icon_box icon_box_style4 box_shadow1">
                <div className="icon">
                  <i className="ti-pencil-alt" />
                </div>
                <div className="icon_box_content">
                  <h5>Widest choice for the sender</h5>
                  <p>
                    Pick from 100+ brands of gift cards, gift vouchers and
                    e-gift cards
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="icon_box icon_box_style4 box_shadow1">
                <div className="icon">
                  <i className="ti-layers" />
                </div>
                <div className="icon_box_content">
                  <h5>Unlimited choice for the recipient</h5>
                  <p>
                    Gift your loved ones unlimited shopping choices at the
                    store.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="icon_box icon_box_style4 box_shadow1">
                <div className="icon">
                  <i className="ti-email" />
                </div>
                <div className="icon_box_content">
                  <h5>Fast delivery</h5>
                  <p>E-gift cards are delivered instantly through E-mail.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="icon_box icon_box_style4 box_shadow1">
                <div className="icon">
                  <i className="ti-email" />
                </div>
                <div className="icon_box_content">
                  <h5>Special templates</h5>
                  <p>
                    A variety of colourful templates to celebrate every occasion
                    in life.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p />
        </div>
      </div>
    </div>
  </div>
  {/* <div className="section bg_light_blue2 pb_70">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="heading_s1 text-center">
            <h2>Why Choose Us?</h2>
          </div>
          <p className="text-center leads">
            For the past 7 years, At Credence Rewards, we take immense pride in
            being your trusted one-stop destination for a diverse selection of
            gift cards from multiple renowned brands.
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-pencil-alt" />
            </div>
            <div className="icon_box_content">
              <h5>Vast Range of Gift Card</h5>
              <p>
                With a vast assortment of gift cards from multiple brands, we
                offer a world of options to suit every occasion and celebration.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-layers" />
            </div>
            <div className="icon_box_content">
              <h5>Instant Delivery</h5>
              <p>
                We understand the importance of timely gifting, and that's why
                we offer instant delivery of e-gift cards through email and
                other sources.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-email" />
            </div>
            <div className="icon_box_content">
              <h5>No Hidden Charges</h5>
              <p>
                Transparency is at the core of our values. When you choose us,
                you can rest assured that there are no hidden charges or
                additional fees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}


    </>
  )
}

export default Faqs