import React, { useState } from 'react'
import SubscribeSection from '../Components/Homepage/SubscribeSection'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { restrictAlpha } from '../constants';
import { register } from '../services/Apiservices';
import { useNavigate } from 'react-router';
import { setMobileNumber } from '../redux/reducers/mobileNumberSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';


function Signup() {
  const navigate=useNavigate()
  const dispatch=useDispatch()

  const [loading,setisLoading]=useState(false)
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, 'Mobile number must contain only digits')
      .min(10, 'Mobile number must be at least 10 digits')
      .required('Mobile number is required'),
    agreeToTerms: Yup.boolean()
      .oneOf([true], 'You must agree to the terms and policy to proceed'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      mobile: '',
      gst_no:"",
      agreeToTerms: false,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleRegister(values)
    },
  });

const handleRegister=async(values)=>{
  setisLoading(true)
  let body={
    "email":values?.email,
    "mobile_no":values?.mobile,
    "full_name":values?.name,
    "gst_no":values?.gst_no
}
  try {
    let response=await register(body)
    if(response?.status==200){
      setisLoading(false)
      dispatch(setMobileNumber(values?.mobile));

      navigate("/verify-otp")
      
    }else{
      setisLoading(false)
    }
  } catch (error) {
    setisLoading(false)
  }
}

  
  
  return (
    <div className="main_content ">
  {/* START LOGIN SECTION */}
  <div className="login_register_wrap section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-md-10 my-5">
          <div className="login_wrap">
            <div className="padding_eight_all bg-white">
              <div className="heading_s1 text-center">
                <h3>Create an Account</h3>
              </div>
              <form
                
                onSubmit={formik.handleSubmit}
                
              >
                <div className="form-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    maxLength={50}
               
                    defaultValue=""
                    placeholder="Full Name"
                    id="full_name"
                    autoComplete="off"
                    required=""
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                   {formik.touched.name && formik.errors.name ? (
          <div className="text-danger">{formik.errors.name}</div>
        ) : null}
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    required=""
                    className="form-control"
                    maxLength={80}
                  
                    defaultValue=""
                    placeholder="Email"
                    id="user_email"
                    autoComplete="off"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                   {formik.touched.email && formik.errors.email ? (
          <div className="text-danger">{formik.errors.email}</div>
        ) : null}
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    minLength={10}
                    maxLength={10}
                    required=""
                  
                    defaultValue=""
                    placeholder="Mobile No"
                    id="mobile_no"
                    autoComplete="off"
                    type="text"
                    onKeyPress={restrictAlpha}
                    name="mobile"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                    {formik.touched.mobile && formik.errors.mobile ? (
          <div className="text-danger">{formik.errors.mobile}</div>
        ) : null}
                </div>
                <div className="form-group mb-3">
                  <input
                    className="form-control"
                    maxLength={20}
                    name="gst_no"
                
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gst_no}
                    defaultValue=""
                    placeholder="Gst No (if applicable) - optional"
                    id="gst_no"
                    autoComplete="off"
                    type="text"
                  />
                  
                </div>
                <div className="login_footer form-group mb-3">
                  <div className="chek-form">
                    <div className="custome-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="agreeToTerms"
          name="agreeToTerms"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          checked={formik.values.agreeToTerms}
                      />
                      <label className="form-check-label" htmlFor="agreeToTerms">
                        <span>I agree to terms &amp; Policy.</span>
                      </label>
                      {formik.touched.agreeToTerms && formik.errors.agreeToTerms ? (
          <div className="text-danger">{formik.errors.agreeToTerms}</div>
        ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  {
                    loading ? <button
                    type="submit"
                    className="btn btn-fill-out btn-block"
                    name="button"
                    disabled
                  >
                    Processing...
                  </button>: <button
                    type="submit"
                    className="btn btn-fill-out btn-block"
                    name="register"
                  >
                    Register
                  </button>
                  }
                 
                </div>
              </form>
              <div className="different_login">
                <span> </span>
              </div>
              <div className="form-note text-center">
                Already have an account?{" "}
                <Link to="/login">Log in</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* END LOGIN SECTION */}
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
 <SubscribeSection/>
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
</div>

  )
}

export default Signup