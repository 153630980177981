import "./SearchResult.css";

export const SearchResult = ({ result,handleOnSelect }) => {
  return (
    <div
      className="search-result"
      onClick={() => handleOnSelect(result)}
    >
       <span style={{ display: "flex", textAlign: "left" }}>{result.name}</span>
        <span style={{ fontSize: "10px" }}>{result?.type}</span>
    </div>
  );
};
