import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

import {
  checkCouponCode,
  createOrder,
  getProductByCatID,
  getProductDetail,
} from "../services/Apiservices";

import { BASE_URL, restrictAlpha, defaultOptions } from "../constants";

import ReactOwlCarousel from "react-owl-carousel";
import SubscribeSection from "./Homepage/SubscribeSection";
import { loginShow } from "../redux/reducers/modalSlice";
import MobileNumberModal from "../AuthComponents/MobileNumberModal";
import { setProductId } from "../redux/reducers/productIdSlice";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet-async";
import { Tilt } from "react-tilt";
import credenceCards from "../assets/CredenceCards.png";
function CheckoutPage() {
  const { productId } = useParams();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const navigate = useNavigate();

  const [productDetail, setProductDetail] = useState("");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [customPrice, setCustomPrice] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [isPriceEdit, setISPriceEdit] = useState(true);
  const [quantMessage, setQuantMessage] = useState("");
  const [demoMessage, setDemoMessage] = useState("");
  const [isCuponSent, setIsCuponSent] = useState(false);
  const [couponMsg, setCouponMsg] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [showCoupon, setShowCoupon] = useState(false);

  const [disable, setDisable] = useState(false);

  const dispatch = useDispatch();

  const [denominatioQuantity, setDenominatioQuantity] = useState(1);
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
    getCategorizedData();
    getRelatedData();
  }, [productId]);

  const getCategorizedData = async () => {
    setLoading(true);
    try {
      let response = await getProductDetail(productId);
      if (response?.status == 200) {
        setProductDetail(response?.data?.data);
        setCustomPrice(response?.data?.data?.checkout?.max);
        setDenominatioQuantity(response?.data?.data?.checkout?.quantity);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setProductDetail("");
    }
  };
  const getRelatedData = async () => {
    try {
      let response = await getProductByCatID(40);
      if (response?.status == 201) {
        setProducts(response?.data?.data);
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const applyCouponCode = async () => {
    try {
      let response = await checkCouponCode(couponCode, productDetail?.id);
      if (response?.data?.status == true) {
        setIsCuponSent(true);
        setCouponMsg(false);
        setCouponData(response?.data);
      }
    } catch (error) {}
  };
  const handleInputFocus = (e) => {
    // Clear any previous error messages
    // Perform validation checks when an input field is focused
    switch (e.target.value) {
      case "denominatioNumber":
        setDemoMessage("");
        if (productDetail?.product_type == 2) {
          if (
            customPrice < productDetail?.checkout?.min ||
            customPrice > productDetail?.checkout?.max
          ) {
            setDemoMessage(`Entered Denomination must be in range from Min 
           ${productDetail?.checkout?.min} 
           and Max
           ${productDetail?.checkout?.max}`);
          }
        } else {
          if (!productDetail?.checkout?.denominations?.includes(customPrice)) {
            setDemoMessage(
              "Entered Denomination must be from given denominations "
            );
          } else {
            setDemoMessage("");
          }
        }

        break;
      case "denominatioQuantity":
        setQuantMessage("");
        if (denominatioQuantity === "") {
          setQuantMessage("Enter Quantity");
        } else if (denominatioQuantity == 0) {
          setQuantMessage("Invaliad quantity");
        } else if (denominatioQuantity > productDetail?.checkout?.quantity) {
          setQuantMessage(
            `Upto ${productDetail?.checkout?.quantity} Cards allowed in one order`
          );
        }

        break;
      default:
        break;
    }
  };
  const handleIncrese = () => {
    if (customPrice < productDetail?.checkout?.max) {
      setCustomPrice((previous) => previous + 10);
    }
  };
  const handleDecrease = () => {
    if (customPrice > productDetail?.checkout?.min) {
      setCustomPrice((previous) => previous - 10);
    }
  };

  const handleCreateOrder = async () => {
    if (isLoggedIn) {
      if (quantMessage == "" && demoMessage == "") {
        setDisable(true);

        let body = {
          "coupon-code": isCuponSent ? couponCode : "",
          prov_id: productDetail?.id,
          denominatioNumber: customPrice,
          denominatioQuantity: denominatioQuantity,
        };

        try {
          let response = await createOrder(body);
          if (response?.status == 200) {
            setDisable(false);

            displayRazorpay(response?.data?.data);
          } else {
            setDisable(false);
          }
        } catch (error) {
          setDisable(false);
        }
      }
    } else {
      dispatch(loginShow());
    }
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay(data) {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: process.env.REACT_APP_SEC_KEY, // Enter the Key ID generated from the Dashboard
      amount: data?.amount,
      currency: "INR",
      name: "Credence Rewards",
      description: data?.provider_name,
      image: "../../../assets/CR Logo.png",
      order_id: data?.order_id,
      handler: async function (response) {
        navigate("/wallet/order-completed");
      },
      prefill: {
        name: data?.name,
        email: data?.email,
        contact: data?.mobile,
      },

      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const handleNaviagte = (item) => {
    dispatch(setProductId(item?.id));

    navigate(`/${item?.url}`);
  };

  const responsiveOptions = {
    0: {
      items: 1, // Display one item on mobile
    },
    768: {
      items: 4, // Display four items on desktop
    },
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  return (
    <>
      {" "}
      <Helmet prioritizeSeoTags>
        <title>
          {productDetail?.discount
            ? `${productDetail?.product_name}s-Get Flat ${productDetail?.discount}% Off`
            : `${productDetail?.product_name} | Claim Your  Gift cards from credence rewards `}
        </title>
        <link
          rel="canonical"
          href={`https://credencerewards.com/${productDetail?.url}`}
        />
        <meta name="description" content={`${productDetail?.desc}`} />
        <meta
          name="keywords"
          content={`${productDetail?.product_name},gift cards, instant voucher, buy ${productDetail?.product_name}, discount ${productDetail?.product_name}, ${productDetail?.product_name} deals, online gift card, digital gift card`}
        />
        <meta
          property="og:title"
          content={`${productDetail?.product_name} | Claim Your  Gift cards from credence rewards `}
        />
        <meta
          property="og:description"
          content={`Elevate your gifting experience with ${productDetail?.product_name} from Credence Rewards. Send instant joy to your friends and family with the ultimate gift solution. Explore seamless online gifting today for hassle-free celebrations.`}
        />
        <meta
          property="og:url"
          content={`https://credencerewards.com/${productDetail?.url}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`https://credencerewards.com/${productDetail?.logo}`}
        />

        <meta
          name="twitter:title"
          content={`${productDetail?.product_name} | Claim Your  Gift cards from credence rewards `}
        />
        <meta
          name="twitter:description"
          content={`Elevate your gifting experience with ${productDetail?.product_name} from Credence Rewards. Send instant joy to your friends and family with the ultimate gift solution. Explore seamless online gifting today for hassle-free celebrations.`}
        />
        <meta
          name="twitter:image"
          content={`https://credencerewards.com/${productDetail?.logo}`}
        />
      </Helmet>
      <div className="main_content ">
        {/* START SECTION SHOP */}
        <div className="" style={{ paddingTop: "60px" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-5 mb-4 mb-md-0">
                <div className="product-image">
                  <Tilt options={defaultOptions}>
                    <div className="single_img p-4 bg-light">
                      <img
                        className="w-100 mb-4 bg-light"
                        src={BASE_URL + productDetail?.logo}
                        alt="blog_single_img1"
                      />
                    </div>
                    {productDetail?.if_available == true ? (
                      <>
                        {productDetail?.discount > 0 && (
                          <div>
                            {/* <span class="bg-success">
                              <strong>{productDetail?.discount}% OFF</strong>
                            </span> */}
                          </div>
                        )}
                      </>
                    ) : (
                      <span className="pr_flash">Out of Stock</span>
                    )}
                  </Tilt>
                  <ul className="product-meta">
                    <div className="col-lg-12">
                      <div className="toggle_info">
                        <span>
                          <i className="fas fa-tag" />
                          Have a coupon?{" "}
                          <a
                            href="#coupon"
                            data-bs-toggle="collapse"
                            className="collapsed text-primary"
                            aria-expanded="false"
                            onClick={() => setShowCoupon(true)}
                          >
                            Click here to enter your code
                          </a>
                        </span>
                      </div>
                      {showCoupon && (
                        <div
                          className="panel-collapse coupon_form show"
                          id="coupon"
                        >
                          <div className="panel-body">
                            {couponMsg && (
                              <div className="row text-center">
                                <p
                                  className="small text-center py-0 my-0 text-success"
                                  id="discount-applied"
                                >
                                  {couponData?.discount} % discount will be
                                  applied to this order.
                                  <br />
                                  <span className="bg-primary text-white">
                                    Note! Choose your vouchers wisely, you will
                                    not be able to use this code again.
                                  </span>
                                </p>
                              </div>
                            )}

                            <div className="coupon field_form input-group">
                              <input
                                type="text"
                                defaultValue=""
                                min={4}
                                max={20}
                                onChange={(e) => setCouponCode(e.target.value)}
                                name="check_coupon_code"
                                className="form-control"
                                placeholder="Enter Coupon Code.."
                                id="check_coupon_code"
                              />
                              <div className="input-group-append">
                                <button
                                  className="btn btn-fill-out btn-sm"
                                  id="discount-code-btn"
                                  type="button"
                                  onClick={applyCouponCode}
                                >
                                  Apply Coupon
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </ul>
                  <div className="product_share row text-center">
                    <ul className="social_icons border">
                      <li>
                        <span>Share:</span>
                      </li>
                      <li>
                        <a
                          href="https://www.facebook.com/sharer/sharer.php?u=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/Amazon Pay E-Gift Card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-facebook" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/intent/tweet?url=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wa.me/?text=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-whatsapp" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/?url=https://www.credencerewards.com/brands/f06cba9b-55f1-4444-a734-90108493cdd4/crs-1/amazon-pay-e-gift-card"
                          target="_blank"
                          rel="noopener"
                        >
                          <i className="ion-social-instagram-outline" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-7 ">
                <div className="pr_detail mx-3">
                  <form
                    className="card-form"
                    action=""
                    method="post"
                    id="checkoutForm"
                    noValidate="novalidate"
                  >
                    <div className="product_description">
                      <h1 className="product_title">
                        <span>{productDetail?.product_name}</span>{" "}
                        {productDetail?.discount > 0 && (
                          <div style={{ color: "green", fontWeight: "700" }}>
                            {productDetail?.discount}% off
                          </div>
                        )}
                      </h1>
                      <hr />
                      <div className="product_sort_info ">
                        <ul>
                          <li>
                            <i className="linearicons-shield-check" />
                            Expiry: {productDetail?.expiry}
                          </li>
                          <li>
                            <i className="linearicons-sync" /> Instant Delivery
                            Via Email.
                          </li>
                          <li>
                            <i className="linearicons-bag-dollar" /> Secure
                            Payment Gateway Available.
                          </li>
                          <li>
                            <i className="linearicons-hourglass" /> No Hidden
                            Charges.
                          </li>

                          {productDetail?.discount > 0 && (
                            <li>
                              <i class="linearicons-linearicons"></i>{" "}
                              <span class="mr-3">
                                Discount - {productDetail?.discount}% off.
                              </span>
                              <div class="text-success">
                                <small>(Will be applied on Payment.)</small>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="pr_switch_wrap">
                        <div className="switch_lable text-center">
                          Choose Denomination
                        </div>
                        <br />
                        <div className="product_size_switch row d-flex justify-content-center">
                          {productDetail?.checkout?.denominations?.map(
                            (data, index) => {
                              return (
                                <a
                                  key={index}
                                  value={data}
                                  onClick={() => setCustomPrice(data)}
                                  data-testid="brandValueDenom0"
                                  className="btn btn-fill-out m-2 font-weight-bold p-1 col-2 BrandValue_container__98UnK"
                                >
                                  {data}
                                </a>
                              );
                            }
                          )}
                          {productDetail?.checkout?.other && (
                            <a
                              data-testid="brandValueDenom0"
                              className="btn btn-fill-out m-2 font-weight-bold p-1 col-2 BrandValue_container__98UnK"
                              onClick={() => setISPriceEdit(false)}
                            >
                              Other
                            </a>
                          )}
                          {productDetail?.checkout?.other ? (
                            <p className="small text-center py-0 my-0">
                              Here you can Select any price from{" "}
                              <b>{productDetail?.checkout?.min}</b> to{" "}
                              <b>{productDetail?.checkout?.max}</b>
                            </p>
                          ) : (
                            <p className="small text-center py-0 my-0">
                              Here you can Select price from above given
                              denominations only.
                            </p>
                          )}

                          <input
                            type="hidden"
                            name="csrfmiddlewaretoken"
                            defaultValue="SFD0H8cM7JwxrieN2cxbArQsz3n1Or5OJnM2xWojnhpY8kFTXKWl7oo9Xufy7GEI"
                          />
                          <input
                            type="hidden"
                            name="coupon-code"
                            id="coupon-code"
                          />
                          <div className="brand-custom-value BrandValue_brand-custom-value__txD8S">
                            <div className="BrandValue_brand-custom-value__content__SQz4G ">
                              <div className="BrandValue_brand-custom-value__amount-wrapper__4ZpoY">
                                <input
                                  type="button"
                                  defaultValue="-"
                                  className=" valid p-2 rounded"
                                  disabled={
                                    productDetail?.product_type == 1
                                      ? true
                                      : false
                                  }
                                  id="minus-btn"
                                  aria-invalid="false"
                                  onClick={handleDecrease}
                                />
                                <span className="BrandValue_brand-custom-value__amount__AQBoY border-0 disabled">
                                  INR
                                </span>
                                <input
                                  type="text"
                                  className="BrandValue_brand-custom-value__amount-input__sa9Om"
                                  name="denominatioNumber"
                                  id="denominatioNumber"
                                  required=""
                                  data-currency="INR"
                                  disabled={isPriceEdit}
                                  max={10000}
                                  min={100}
                                  autoFocus={isPriceEdit}
                                  onKeyPress={restrictAlpha}
                                  value={customPrice}
                                  onChange={(e) =>
                                    setCustomPrice(e.target.value)
                                  }
                                  onFocus={handleInputFocus}
                                  onBlur={handleInputFocus}
                                  style={{ width: "6ch" }}
                                />

                                <input
                                  type="button"
                                  defaultValue="+"
                                  className=" valid p-2 rounded"
                                  id="plus-btn"
                                  disabled={
                                    productDetail?.product_type == 1
                                      ? true
                                      : false
                                  }
                                  aria-invalid="false"
                                  onClick={handleIncrese}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="text-danger"
                            style={{ textAlign: "center" }}
                          >
                            {quantMessage}
                            <br />
                            {demoMessage}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex align-items-end gap-3">
                      <div
                        className=" text-center"
                        // style={{ marginTop: "-10px !important" }}
                      >
                        <label htmlFor="denominatioQuantity" className="small">
                          <small>Quantity</small>
                        </label>
                        <div className="quantity">
                          {/* <input type="button" value="-" class="minus" onclick="updateInput(10)"> */}
                          <input
                            type="text"
                            min={1}
                            max={100}
                            maxLength={2}
                            value={denominatioQuantity}
                            id="denominatioQuantity"
                            onChange={(e) =>
                              setDenominatioQuantity(e.target.value)
                            }
                            onFocus={handleInputFocus}
                            onBlur={handleInputFocus}
                            style={{ width: "12ch" }}
                            name="denominatioQuantity"
                            title="Quantity"
                            className="qty"
                            size=""
                          />
                          {/* <input type="button" value="+" class="plus" onclick="updateInput(10)"> */}
                        </div>
                      </div>
                      <div className="cart_btn pull-right">
                        {disable ? (
                          <button
                            className="btn btn-fill-line"
                            type="button"
                            disabled
                          >
                            <i className="icon-rocket" />{" "}
                            <span
                              className="Button_button-label__yl844 button-label "
                              id="proceed-to-pay-btn"
                            >
                              <b>Processing Payment...</b>
                            </span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-fill-line"
                            type="button"
                            onClick={handleCreateOrder}
                          >
                            <i className="icon-rocket" />{" "}
                            <span
                              className="Button_button-label__yl844 button-label "
                              id="proceed-to-pay-btn"
                            >
                              <b>
                                Continue to pay ₹{" "}
                                {denominatioQuantity == 0
                                  ? customPrice
                                  : denominatioQuantity * customPrice}
                              </b>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="large_divider clearfix" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="tab-style3">
                  <ul className="nav nav-tabs" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="Description-tab"
                        data-bs-toggle="tab"
                        href="#Description"
                        role="tab"
                        aria-controls="Description"
                        aria-selected="true"
                      >
                        Description
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="Additional-info-tab"
                        data-bs-toggle="tab"
                        href="#Additional-info"
                        role="tab"
                        aria-controls="Additional-info"
                        aria-selected="false"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="Reviews-tab"
                        data-bs-toggle="tab"
                        href="#Reviews"
                        role="tab"
                        aria-controls="Reviews"
                        aria-selected="false"
                      >
                        How to redeem
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content shop_info_tab">
                    <div
                      className="tab-pane fade active show"
                      id="Description"
                      role="tabpanel"
                      aria-labelledby="Description-tab"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.desc,
                        }}
                      ></p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Additional-info"
                      role="tabpanel"
                      aria-labelledby="Additional-info-tab"
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: productDetail?.t_c_content,
                        }}
                      ></p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Reviews"
                      role="tabpanel"
                      aria-labelledby="Reviews-tab"
                    >
                      <p></p>
                      <ul>
                        <li>
                          <div className="">
                            {productDetail?.how_to_redeem
                              ? productDetail?.how_to_redeem
                              : ` Go to Selected Barnd's Website and select the items you
                        want to purchase. When you are ready to checkout, click
                        ""Proceed To Pay"".`}
                          </div>
                        </li>
                        <li>
                          <div className="">
                            Select the ""Pay By Gift Card"" option.
                          </div>
                        </li>
                        <li>
                          <div className="">
                            Enter your 16-digit gift card number and PIN
                            number(if needed).
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="small_divider" />
                <div className="divider" />
                <div className="medium_divider" />
              </div>
            </div>
            {/* START SECTION SHOP */}
            <div className="row">
              <div className="col-md-12">
                {products?.length !== 0 && (
                  <ReactOwlCarousel
                    className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
                    margin={10}
                    // center={true}
                    dots={true}
                    autoplay={true}
                    responsive={responsiveOptions}
                  >
                    {products?.length !== 0 ? (
                      <>
                        {products?.slice(0, 10)?.map((item, index) => {
                          return (
                            <div
                              className="shadow mx-auto mx-sm-0"
                              style={{
                                width: "218px",
                                height: "100%",
                                borderRadius: "8px",
                                borderTopRightRadius: "14px",
                                borderTopLeftRadius: "14px",
                                // paddingLeft: "8px",
                                // paddingRight: "8px",

                                border: "1.5px solid #ECECEC",
                              }}
                              key={index}
                            >
                              <Tilt options={defaultOptions}>
                                {item?.logo ? (
                                  <LazyLoad
                                    key={item?.id}
                                    once={true}
                                    placeholder={
                                      <img
                                        src={credenceCards}
                                        alt="Placeholder"
                                        style={{
                                          width: "100% !important",
                                          height: "163px !important",
                                          padding: "7px 8px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    }
                                  >
                                    <img
                                      src={BASE_URL + item?.logo}
                                      alt="Image Alt"
                                      className="card-img-top"
                                      style={{
                                        width: "100% !important",
                                        height: "163px !important",
                                        padding: "7px 8px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </LazyLoad>
                                ) : (
                                  <img
                                    src={credenceCards}
                                    className="col-12"
                                    style={{
                                      width: "100% !important",
                                      height: "163px !important",
                                      padding: "7px 8px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      item?.if_available
                                        ? handleNaviagte(item)
                                        : alert(
                                            "Brand is out of stock or not available this time, please select other brand."
                                          );
                                    }}
                                    alt="Credence Rewards-card"
                                  />
                                )}
                              </Tilt>
                              <div
                                className="pt-2"
                                style={{
                                  textAlign: "center",
                                  fontWeight: "600",
                                  color: "black",
                                  fontSize: "13px",
                                }}
                                onClick={() => {
                                  item?.if_available
                                    ? handleNaviagte(item)
                                    : alert(
                                        "Brand is out of stock or not available this time, please select other brand."
                                      );
                                }}
                              >
                                {truncateText(item?.product_name, 30)}
                              </div>
                              {/* <div className="d-flex justify-content-center ">
                          {item?.price_json?.denominations
                            ?.slice(0, 3)
                            .map((element, index) => (
                              <span
                                className="px-1"
                                style={{
                                  fontSize: "12.5px",
                                }}
                                key={index}
                              >
                                {element}
                              </span>
                            ))}
                        </div> */}
                              <div
                                className="d-flex justify-content-between align-items-center pt-3"
                                style={{
                                  padding: "7px 8px",
                                }}
                              >
                                {item?.if_available == true ? (
                                  <>
                                    {item?.discount > 0 ? (
                                      <div
                                        style={{
                                          paddingLeft: "3px",
                                          borderLeft: "3px solid #FF324D",
                                          color: "#FF324D",
                                          fontSize: "18px",
                                          borderRadius: "1px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        {item?.discount}%
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                            color: "#000",
                                          }}
                                        >
                                          Discount
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          paddingLeft: "3px",
                                          borderLeft: "3px solid #FF324D",
                                          color: "#000",
                                          fontSize: "18px",
                                          borderRadius: "1px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Instant
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          Delivery
                                        </div>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <span>Out of Stock</span>
                                )}

                                <div
                                  style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    padding: "5px ",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    fontSize: "12px",
                                    fontWeight: "700",
                                    cursor: "pointer",
                                    borderRadius: "8px",
                                  }}
                                  onClick={() => {
                                    item?.if_available
                                      ? handleNaviagte(item)
                                      : alert(
                                          "Brand is out of stock or not available this time, please select other brand."
                                        );
                                  }}
                                >
                                  BUY NOW
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>No Products found for this category</>
                    )}
                  </ReactOwlCarousel>
                )}
              </div>
            </div>
            {/* END SECTION SHOP */}
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
      {showMobileModal && (
        <MobileNumberModal
          showLogin={showMobileModal}
          hideLogin={setShowMobileModal}
        />
      )}
    </>
  );
}

export default CheckoutPage;
