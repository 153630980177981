import logo from "./logo.svg";
import "./App.css";
import Header from "./SubComponents/Header";
import Homepage from "./Components/Homepage/Homepage";
import Footer from "./SubComponents/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter";
import { ToastContainer } from "react-toastify";

import { HelmetProvider } from "react-helmet-async";
function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={20000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <TawkMessengerReact
        propertyId="65a8bad88d261e1b5f54ac43"
        widgetId="1hkdhjnoj"
      /> */}
      {/* <div className="preloader">
  <div className="lds-ellipsis">
    <span />
    <span />
    <span />
  </div>
</div> */}
      <Router>
        <HelmetProvider>
          <AppRouter />
        </HelmetProvider>
      </Router>
    </>
  );
}

export default App;
