import React from "react";
import Slider from "./Slider";
import MainContent from "./MainContent";
import { Helmet } from "react-helmet-async";
function Homepage() {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>
          CredenceRewards.com:Buy Gift Cards Online Now And Get Instant Delivery
        </title>
        <link rel="canonical" href="https://credencerewards.com" />
        <meta
          name="description"
          content="Shop the best deals on gift cards online at CredenceRewards. Save on top brands and earn rewards with every purchase!"
        />
        <meta
          name="keywords"
          content="buy gift cards online, discount gift cards, gift card deals, gift card rewards, CredenceRewards,all brand, gifts online solution"
        />
        <meta
          property="og:title"
          content="CredenceRewards.com:Buy Gift Cards Online Now And Get Instant Delivery"
        />
        <meta
          property="og:description"
          content="Discover the best deals on gift cards at CredenceRewards. Purchase and save on gift cards from top brands while earning rewards!"
        />
        <meta property="og:url" content="https://credencerewards.com/" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://credencerewards.com/assets/Credence%20Rewards%20213_page-0001.jpg"
        />
        
        <meta
          name="twitter:title"
          content="Buy Gift Cards Online | Top Deals & Rewards at CredenceRewards"
        />
        <meta
          name="twitter:description"
          content="Shop the best deals on gift cards online at CredenceRewards. Save on top brands and earn rewards with every purchase!"
        />
        <meta
          name="twitter:image"
          content="https://credencerewards.com/assets/Credence%20Rewards%20213_page-0001.jpg"
        />
      </Helmet>

      <Slider />
      <MainContent />
    </>
  );
}

export default Homepage;
