import { addSubscription } from "../../services/Apiservices";
import showNotification from "../../services/NotificationService";
import React, { useState } from "react";

function SubscribeSection() {
  const [email, setEmail] = useState("");

  const handleSubmit = async () => {
    if (email !== "") {
      let body = {
        email,
      };
      try {
        let response = await addSubscription(body);
        if (response?.status == 201) {
          setEmail("");
          showNotification("success", response?.data?.message);
        }
      } catch (error) {}
    } else {
      showNotification("danger", "Please enter your email");
    }
  };
  return (
    <div className="section bg_default small_pt small_pb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="heading_s1 mb-md-0 heading_light">
              <h3>Subscribe Our Newsletter</h3>
            </div>
          </div>
          <div className="col-md-6">
            <div className="">
              <form className="d-flex">
                <input
                  type="text"
                  required=""
                  className="form-control "
                  placeholder="Enter Email Address"
                  value={email}
                  style={{
                    borderStartStartRadius: "20px",
                    borderEndStartRadius: "20px",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-dark  "
                  name="submit"
                  style={{
                    borderEndEndRadius: "20px",
                    borderTopRightRadius: "20px",
                  }}
                  onClick={() => handleSubmit()}
                >
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeSection;
