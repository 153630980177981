import React, { useEffect, useState } from "react";
import SubscribeSection from "./Homepage/SubscribeSection";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllBrands } from "../services/Apiservices";

import ReactPaginate from "react-paginate";

import { Helmet } from "react-helmet-async";

import Gifcard from "./Gifcard";
function AllBrands() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    getCategorizedData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, [currentPage, location?.state]);

  const getCategorizedData = async () => {
    try {
      setLoading(true); // Set loading to true immediately

      let response = await getAllBrands(
        currentPage * 12 * 2,
        location?.state ? location?.state : ""
      );

      if (response?.status == 200) {
        setProducts(response?.data);
        setPageCount(Math.floor(response?.data?.count / 10));

        // Bug: Delayed setting loading to false
        setTimeout(() => {
          setLoading(false);
        }, 500);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 500);

        setProducts([]);
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 500);

      setProducts([]);
    }
  };

  const handleNaviagte = (item) => {
    console.log(item);
    navigate(`/${item?.url}`);
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>
          All Brands | Buy Gift Cards from Top Brands at Best Deals from
          CredenceRewards
        </title>
        <link
          rel="canonical"
          href="https://credencerewards.com/brands/all-brands"
        />
        <meta
          name="description"
          content="Explore and buy gift cards from all your favorite brands at CredenceRewards. Enjoy great deals and earn rewards on gift cards from top brands."
        />
        <meta
          name="keywords"
          content="all brands, buy gift cards, top brands, discount gift cards, gift card deals, CredenceRewards"
        />

        <meta
          property="og:title"
          content="All Brands | Buy Gift Cards from Top Brands at CredenceRewards"
        />
        <meta
          property="og:description"
          content="Explore and buy gift cards from all your favorite brands at CredenceRewards. Enjoy great deals and earn rewards on gift cards from top brands."
        />
        <meta
          property="og:url"
          content="https://credencerewards.com/brands/all-brands"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://credencerewards.com/assets/Credence%20Rewards%20213_page-0001.jpg"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="All Brands | Buy Gift Cards from Top Brands at CredenceRewards"
        />
        <meta
          name="twitter:description"
          content="Explore and buy gift cards from all your favorite brands at CredenceRewards. Enjoy great deals and earn rewards on gift cards from top brands."
        />
        <meta
          name="twitter:image"
          content="https://credencerewards.com/assets/Credence%20Rewards%20213_page-0001.jpg"
        />
      </Helmet>

      <div className="main_content ">
        {/* START SECTION BLOG */}
        <div className="section">
          <div className="container">
            <h1 className="text-left fs-4">All Brands </h1>
            <div
              className="justify-content-center justify-content-md-start "
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {products?.results?.length !== 0 ? (
                <>
                  {products?.results?.map((item, index) => {
                    return (
                      <Gifcard item={item} handleNaviagte={handleNaviagte} />
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
            {products?.count >= 12 && (
              <div className="row">
                <div className="col-12 mt-2 mt-md-4 ">
                  <ReactPaginate
                    className="pagination pagination_style1 justify-content-center"
                    previousLabel="Prev"
                    breakLabel="..."
                    pageCount={pageCount}
                    onPageChange={({ selected }) =>
                      setCurrentPage(selected + 1)
                    }
                    containerClassName="pagination"
                    activeClassName="active"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* END SECTION BLOG */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default AllBrands;
