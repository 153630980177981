import React from "react";
import { Route, Routes } from "react-router";

import Footer from "./SubComponents/Footer";
import Homepage from "./Components/Homepage/Homepage";
import Login from "./AuthComponents/Login";
import Signup from "./AuthComponents/Signup";
import AllBrands from "./Components/AllBrands";
import CorporateGifting from "./Components/b2b/CorporateGifting";
import CheckBalance from "./Components/CheckBalance";
import ContactUs from "./SubComponents/ContactUs";
import AboutUs from "./SubComponents/AboutUs";
import PrivacyPolicy from "./SubComponents/PrivacyPolicy";
import Faqs from "./SubComponents/Faqs";
import TermsAndCondition from "./SubComponents/TermsAndCondition";
import VerifyOtp from "./AuthComponents/VerifyOtp";
import Account from "./Components/Accounts/Account";
import MyOrderList from "./Components/Accounts/MyOrderList";
import MyRedeemOrders from "./Components/Accounts/MyRedeemOrders";
import RedeemVocher from "./Components/RedeemVocher";
import CheckoutPage from "./Components/CheckoutPage";
import OrderDetails from "./Components/Accounts/OrderDetails";
import CategroyBrands from "./Components/CategroyBrands";
import OrderCompleted from "./Components/OrderCompleted";
import Proposal from "./Components/Proposal";
import LifeStyleCheckout from "./Components/LifeStyleCheckout";
import Header from "./SubComponents/Header";

function AppRouter() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/brands/all-brands" element={<AllBrands />} />
        <Route path="/b-2-b/corporate-gifting" element={<CorporateGifting />} />
        <Route path="/brands/check-balance" element={<CheckBalance />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/terms-&-conditions" element={<TermsAndCondition />} />
        <Route path="/accounts/my-account" element={<Account />} />
        <Route path="/wallet/my-orders" element={<MyOrderList />} />
        <Route path="/cr/my-redeem-orders" element={<MyRedeemOrders />} />
        <Route path="/cr/enter-cr-code" element={<RedeemVocher />} />
        <Route path="/:productId" element={<CheckoutPage />} />
        <Route
          path="/wallet/order/:orderId/details"
          element={<OrderDetails />}
        />
        <Route
          path="/brand/:categoryId/category/:categoryName"
          element={<CategroyBrands />}
        />

        <Route path="/wallet/order-completed" element={<OrderCompleted />} />
        <Route path="/proposal" element={<Proposal />} />
        <Route path="brand/:customId" element={<LifeStyleCheckout />} />
      </Routes>
      <Footer />
    </>
  );
}

export default AppRouter;
