import React, { useEffect, useState } from "react";
import {
  getHomepageProduct,
  getProductByCatID,
} from "../../services/Apiservices";
import LazyLoad from "react-lazyload";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, defaultOptions } from "../../constants";
import { Link } from "react-router-dom";
import { loginShow } from "../../redux/reducers/modalSlice";
import { setProductId } from "../../redux/reducers/productIdSlice";
import credenceCards from "../../assets/CredenceCards.png";
import { Tilt } from "react-tilt";
import Gifcard from "../Gifcard";

function ShopSection() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCategorizedData();
  }, []);

  const getCategorizedData = async () => {
    try {
      setLoading(true);
      let response = await getHomepageProduct();
      if (response?.status == 200) {
        setProducts(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);

        setProducts([]);
      }
    } catch (error) {
      setLoading(false);

      setProducts([]);
    }
  };

  const handleNaviagte = (item) => {
    dispatch(setProductId(item?.id));
    navigate(`/${item?.url}`);

    // for lifestyle and shopperstop
    // if (item?.id == "239") {
    //   navigate(`/brand/${item?.url}`);
    // } else if (item?.id == "240") {
    //   navigate(`/brand/${item?.url}`);
    // } else {
    //   navigate(`/${item?.url}`);
    // }

    // if (isLoggedIn) {
    //   navigate(`/brands/${item?.id}/${item?.name}`);
    // } else {
    //   dispatch(loginShow())
    // }
  };

  return (
    <>
      <div
        className="small_pt pb_70"
        style={{ marginTop: "38px", marginBottom: "10px" }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="">
              <div className=" d-flex align-items-center gap-3 justify-content-between text-left">
                <h4 style={{ fontWeight: "500" }}>Popular gift cards</h4>
                <div
                  style={{
                    width: "80%",
                    height: "2px",
                    marginBottom: "12px",
                    backgroundColor: "lightgrey",
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div
            className="justify-content-md-between justify-content-center "
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "17px",
            }}
          >
            {products?.length !== 0 ? (
              <>
                {products?.slice(0, 12)?.map((item, index) => {
                  return (
                    <Gifcard item={item} handleNaviagte={handleNaviagte} />
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="section bg_light_blue2 pb-0 pt-md-0">
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-md-6 offset-md-1">
              <div className="medium_divider d-none d-md-block clearfix" />
              <div className="trand_banner_text text-center text-md-start">
                <div className="heading_s1 mb-3">
                  <span className="sub_heading">New season trends!</span>
                  <h2>Discount upto 10% on LifeStyle and ShopperStop</h2>
                </div>
                <h5 className="mb-4">Season Sale upto 10% Discount</h5>
                {/* <a
                  href="https://credencerewards.co.in/lifestyle"
                  target="_blank"
                  className="btn btn-fill-out rounded-0"
                >
                  Shop Now
                </a> */}
              </div>
              <div className="medium_divider clearfix" />
            </div>
            <div className="col-md-5">
              <div className="text-center trading_img">
                <img
                  src="assets/index-banner/LifestyleBanner2.png"
                  alt="tranding_img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShopSection;
