import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Yup from 'yup';
import { sendOtp, verifyOtp } from '../services/Apiservices';
import { restrictAlpha } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setSession } from '../session';
import { setUser } from '../redux/reducers/userSlice';
import { login } from '../redux/reducers/authSlice';

function VerifyOtp() {
  const location=useLocation()
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const mobileNumber = useSelector((state) => state.mobileNumber);
  const [loading,setisLoading]=useState(false)


  const validationSchema = Yup.object({
    otp: Yup.string()
      .required('OTP is required'),
 
  });

  const formik = useFormik({
    initialValues: {
      otp: '',
     
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleSubmitOtp(values)
    },
  });

  const handleSubmitOtp=async(values)=>{
    setisLoading(true)
    let body={
      "mobile":mobileNumber,
      "otp":values?.otp,
      "device_token":"",
      "device_type":"",
      "device_name":""
  
  }
    try {
      let response=await verifyOtp(body)
      if(response?.status==200){
        setisLoading(false)
        dispatch(login());
        dispatch(setUser(response?.data?.details));
        setSession(response?.data?.access_token)
        localStorage.setItem('userDetails',JSON.stringify(response?.data?.details))
        navigate("/")
        
      }else{
        setisLoading(false)
      }
    } catch (error) {
      setisLoading(false)
    }
  }
  const resendOtp=async()=>{
    setisLoading(true)
  
    let body={
      "mobile":mobileNumber,
      
  }
    try {
      let response=await sendOtp(body)
      if(response?.status==200){
      setisLoading(false)
        
      }else{
        setisLoading(false)
      }
    } catch (error) {
      setisLoading(false)

    }
  }
  return (
    <>
    <div className="main_content">
  {/* START LOGIN SECTION */}
  <div className="login_register_wrap section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-md-10 my-5">
          <div className="login_wrap">
            <div className="padding_eight_all bg-white">
              <div className="heading_s1 text-center">
                <h3 className="d-inline">Verify OTP</h3>
              </div>
              <form
                onSubmit={formik.handleSubmit}
              
                className="mt-5 mb-5"
                
                id="sign_up_form"
                
              >
                <div className="form-group mb-2 py-2 text-center">
                  <h5>
                    OTP Sent to <u>{mobileNumber}</u>{" "}
                    <Link
                      to="/login"
                      title="Change Mobile No"
                      style={{ padding: 3 }}
                    >
                      <span className="ti-pencil" />
                    </Link>
                  </h5>
                </div>
                <div className="form-group mb-1 py-3">
                  <input
                    maxLength={6}
                    minLength={6}
                    name="otp"
                    defaultValue=""
                    placeholder="Enter OTP"
                    id="mobile_otp"
                    autoComplete="off"
                    onKeyPress={restrictAlpha}
                    type="text"
                    required=""
                    className="form-control "
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                  />
                             {formik.touched.otp && formik.errors.otp ? (
          <div className="text-danger">{formik.errors.otp}</div>
        ) : null}
                </div>
                <div className="form-note mt-0 my-3">
                  Issue in OTP?
                  <Link
        to=""
        className=""
        onClick={resendOtp}
      >
        Resend OTP
      </Link>
                  
                </div>
                <div className="form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-fill-out btn-block"
                    name="login"
                  >
                    Verify OTP
                  </button>
                </div>
              </form>
              <div className="different_login">
                <span> </span>
              </div>
              <div className="form-note text-center">
                Don't Have an Account?{" "}
                <Link to="/signup/">Sign up now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* END LOGIN SECTION */}
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
  <div className="section bg_default small_pt small_pb">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-6">
          <div className="heading_s1 mb-md-0 heading_light">
            <h3>Subscribe Our Newsletter</h3>
          </div>
        </div>
        <div className="col-md-6">
          <div className="newsletter_form">
            <form>
              <input
                type="text"
                required=""
                className="form-control rounded-0"
                placeholder="Enter Email Address"
              />
              <button
                type="submit"
                className="btn btn-dark rounded-0"
                name="submit"
                value="Submit"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
</div>
{
  loading &&     <div className="preloader">
  <div className="lds-ellipsis">
    <span />
    <span />
    <span />
  </div>
</div> 
}
    </>
  )
}

export default VerifyOtp