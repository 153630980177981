import React, { useEffect, useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  getHomepageProduct,
  getProductByCatID,
} from "../../services/Apiservices";
import { BASE_URL, defaultOptions } from "../../constants";
import { Link } from "react-router-dom";
import credenceCards from "../../assets/CredenceCards.png";
import { loginShow } from "../../redux/reducers/modalSlice";
import { setProductId } from "../../redux/reducers/productIdSlice";
import { Tilt } from "react-tilt";
import LazyLoad from "react-lazyload";
function FeatureProduct() {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCategorizedData();
  }, []);

  const getCategorizedData = async () => {
    setLoading(true);
    try {
      let response = await getProductByCatID(43);
      if (response?.status == 200) {
        setProducts(response?.data?.data);
        setLoading(false);
      } else {
        setLoading(false);

        setProducts([]);
      }
    } catch (error) {
      setLoading(false);

      setProducts([]);
    }
  };

  const handleNaviagte = (item) => {
    dispatch(setProductId(item?.id));
    navigate(`/${item?.url}`);

    //     if(isLoggedIn){
    // navigate(`/brands/${item?.id}/${item?.name}`)
    //     }else{
    //       dispatch(loginShow())
    //     }
  };
  const responsiveOptions = {
    0: {
      items: 1,
      center: true,
      stagePadding: 5,
      lazyLoad: true,
    },
    768: {
      items: 4, // Display four items on desktop
      // center: true,
      lazyLoad: true,
    },
  };
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  return (
    <>
      <div className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="heading_s1 text-center">
                <h2>Featured Products</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {products?.length !== 0 && (
                <ReactOwlCarousel
                  className="testimonial_wrap testimonial_style1 carousel_slider owl-carousel owl-theme nav_style2"
                  margin={10}
                  // center={true}
                  dots={true}
                  autoplay={true}
                  responsive={responsiveOptions}
                >
                  {products?.length !== 0 ? (
                    <>
                      {products?.slice(0, 10)?.map((item, index) => {
                        return (
                          <div
                            className="shadow mx-auto mx-sm-0"
                            style={{
                              width: "218px",
                              height: "100%",
                              borderRadius: "8px",
                              borderTopRightRadius: "14px",
                              borderTopLeftRadius: "14px",
                              // paddingLeft: "8px",
                              // paddingRight: "8px",

                              border: "1.5px solid #ECECEC",
                            }}
                            key={index}
                          >
                            <Tilt options={defaultOptions}>
                              {item?.logo ? (
                                <LazyLoad
                                  key={item?.id}
                                  once={true}
                                  placeholder={
                                    <img
                                      src={credenceCards}
                                      alt="Placeholder"
                                      style={{
                                        width: "100% !important",
                                        height: "163px !important",
                                        padding: "7px 8px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  }
                                >
                                  <img
                                    src={BASE_URL + item?.logo}
                                    alt="Image Alt"
                                    className="card-img-top"
                                    style={{
                                      width: "100% !important",
                                      height: "163px !important",
                                      padding: "7px 8px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </LazyLoad>
                              ) : (
                                <img
                                  src={credenceCards}
                                  className="col-12"
                                  style={{
                                    width: "100% !important",
                                    height: "163px !important",
                                    padding: "7px 8px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    item?.if_available
                                      ? handleNaviagte(item)
                                      : alert(
                                          "Brand is out of stock or not available this time, please select other brand."
                                        );
                                  }}
                                  alt="Credence Rewards-card"
                                />
                              )}
                            </Tilt>
                            <div
                              className="pt-2"
                              style={{
                                textAlign: "center",
                                fontWeight: "600",
                                color: "black",
                                fontSize: "13px",
                              }}
                              onClick={() => {
                                item?.if_available
                                  ? handleNaviagte(item)
                                  : alert(
                                      "Brand is out of stock or not available this time, please select other brand."
                                    );
                              }}
                            >
                              {truncateText(item?.product_name, 30)}
                            </div>
                            {/* <div className="d-flex justify-content-center ">
                          {item?.price_json?.denominations
                            ?.slice(0, 3)
                            .map((element, index) => (
                              <span
                                className="px-1"
                                style={{
                                  fontSize: "12.5px",
                                }}
                                key={index}
                              >
                                {element}
                              </span>
                            ))}
                        </div> */}
                            <div
                              className="d-flex justify-content-between align-items-center pt-3"
                              style={{
                                padding: "7px 8px",
                              }}
                            >
                              {item?.if_available == true ? (
                                <>
                                  {item?.discount > 0 ? (
                                    <div
                                      style={{
                                        paddingLeft: "3px",
                                        borderLeft: "3px solid #FF324D",
                                        color: "#FF324D",
                                        fontSize: "18px",
                                        borderRadius: "1px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      {item?.discount}%
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                          color: "#000",
                                        }}
                                      >
                                        Discount
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        paddingLeft: "3px",
                                        borderLeft: "3px solid #FF324D",
                                        color: "#000",
                                        fontSize: "18px",
                                        borderRadius: "1px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Instant
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        Delivery
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <span>Out of Stock</span>
                              )}

                              <div
                                style={{
                                  backgroundColor: "black",
                                  color: "white",
                                  padding: "5px ",
                                  paddingLeft: "10px",
                                  paddingRight: "10px",
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  cursor: "pointer",
                                  borderRadius: "8px",
                                }}
                                onClick={() => {
                                  item?.if_available
                                    ? handleNaviagte(item)
                                    : alert(
                                        "Brand is out of stock or not available this time, please select other brand."
                                      );
                                }}
                              >
                                BUY NOW
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>No Products found for this category</>
                  )}
                </ReactOwlCarousel>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeatureProduct;
