// authSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showLogin:false,
  showSignup:false // Initialize based on whether a token exists
};
  
const modalSlice= createSlice({
  name: 'modal',
  initialState,
  reducers: {
    loginShow: (state) => {
      state.showLogin = true;
    },
    loginHide: (state) => {
        state.showLogin = false;
      },
    signUpShow: (state) => {
      state.showSignup = true;
    },
    signUpHide: (state) => {
        state.showSignup = false;
      },
  },
});

export const { loginShow, loginHide,signUpShow,signUpHide } = modalSlice.actions;
export default modalSlice.reducer;