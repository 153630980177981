import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addContactUs } from "../services/Apiservices";
import showNotification from "../services/NotificationService";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, []);

  const [loading, setisLoading] = useState(false);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .required("Mobile number is required"),
    subject: Yup.string().required("Subject is required"),
    query: Yup.string().required("Query is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobile: "",
      subject: "",
      query: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleRegister(values);
    },
  });

  const handleRegister = async (values) => {
    setisLoading(true);
    let body = {
      contact_name: values?.name,
      contact_email: values?.email,
      contact_mobile_no: values?.mobile,
      contact_subject: values?.subject,
      contact_message: values?.query,
    };
    try {
      let response = await addContactUs(body);
      if (response?.status == 200) {
        setisLoading(false);
        showNotification("success", response?.data?.message);
        formik.handleReset();
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };
  return (
    <>
      <>
        {/* START MAIN CONTENT */}
        <div className="">
          <div className="">
            {/* <div className="row">
              <div className="col-xl-4 col-md-6">
                <div className="contact_wrap contact_style3">
                  <div className="contact_icon">
                    <i className="linearicons-map2" />
                  </div>
                  <div className="contact_text">
                    <span>Address</span>
                    <p>
                      <small>
                        {" "}
                        C/ 506 Neelkanth Business Park, Nathani Road, Vidyavihar
                        West, Mumbai Maharashtra 400086 India.
                      </small>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="contact_wrap contact_style3">
                  <div className="contact_icon">
                    <i className="linearicons-envelope-open" />
                  </div>
                  <div className="contact_text">
                    <span>Email Address</span>
                    <br />
                    <a href="mailto:support@credencerewards.com">
                      support@credencerewards.com{" "}
                    </a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-md-6">
                <div className="contact_wrap contact_style3">
                  <div className="contact_icon">
                    <i className="linearicons-tablet2" />
                  </div>
                  <div className="contact_text">
                    <span>Whatsapp</span>
                    <br />
                    <a
                      href="https://api.whatsapp.com/send?phone=917977276921"
                      onClick="return confirm('Go to whatsapp')"
                    >
                      +91 79772 76921
                    </a>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* END MAIN CONTENT */}
        <div className=" my-5">
          <div className="container">
            <div className="row">
              <div
                style={{ borderRadius: "20px", width:"80%"  }}
                className=" border border-2  mx-auto"
              >
                <div className="heading_s1">
                  <h2 style={{ color: "#FF324D" }}>Get In touch</h2>
                </div>
                <div className="dec">
                  If you have any questions or complains please fill out the
                  form below and we'll get back to you as soon as possible.
                </div>
                <div className="field_form">
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="hidden"
                      name="csrfmiddlewaretoken"
                      defaultValue="lFOMl9iR45CpPSeRrlPBs5lnwJbWMh76289zDKIqKHHUwyCBiaHdAv9EHsuz2r0K"
                    />
                    <div className="row">
                      <div className="form-group col-md-6 mb-3">
                        <input
                          className="form-control"
                          maxLength={50}
                          name="name"
                          defaultValue=""
                          placeholder="Name"
                          id="contact_name"
                          autoComplete="off"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <input
                          className="form-control"
                          maxLength={60}
                          name="email"
                          defaultValue=""
                          placeholder="Email"
                          id="contact_email"
                          autoComplete="off"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <input
                          placeholder="Enter Mobile No. - optional"
                          minLength={10}
                          maxLength={13}
                          id="contact_mobile_no"
                          className="form-control"
                          name="mobile"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6 mb-3">
                        <input
                          className="form-control"
                          maxLength={120}
                          name="subject"
                          defaultValue=""
                          placeholder="Subject"
                          id="contact_subject"
                          autoComplete="off"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subject}
                        />
                        {formik.touched.subject && formik.errors.subject ? (
                          <div className="text-danger">
                            {formik.errors.subject}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <textarea
                          className="form-control"
                          required=""
                          style={{ height: 100 }}
                          cols={50}
                          charswidth={23}
                          name="query"
                          id="contact_message"
                          autoComplete="off"
                          type="text"
                          rows={8}
                          placeholder="Describe your query here..."
                          defaultValue={""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.query}
                        />
                        {formik.touched.query && formik.errors.query ? (
                          <div className="text-danger">
                            {formik.errors.query}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-12 mb-3">
                        <div className="error capcha-error-msg " />
                        <div className="input-group single-field col-12">
                          <div
                            className="g-recaptcha"
                            data-sitekey="6LcrwhsmAAAAAH8-1aEACPdZrORnP25k_qqZGYd9"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <button
                          type="submit"
                          title="Submit Your Message!"
                          className="btn btn-fill-out"
                          id="submitButton"
                          name="submit"
                          value="Submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* <div className="col-lg-6 pt-2 pt-lg-0 mt-4 mt-lg-0">
                <iframe
                  title="credencerewards-Map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.5988416161804!2d72.9027723191402!3d19.081367506109665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c6293a5c0969%3A0x2d6a5ba2ce9fe093!2sCredence%20Rewards!5e0!3m2!1sen!2sin!4v1690788509497!5m2!1sen!2sin"
                  width={600}
                  height={450}
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div> */}
            </div>
          </div>
        </div>
        {/* <div className="section pt-0 ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8">
                <div className="heading_s1 text-center">
                  <h2>Why Choose Us?</h2>
                </div>
                <p className="text-center leads">
                  For the past 7 years, At Credence Rewards, we take immense
                  pride in being your trusted one-stop destination for a diverse
                  selection of gift cards from multiple renowned brands.
                </p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-pencil-alt" />
                  </div>
                  <div className="icon_box_content">
                    <h5>Vast Range of Gift Card</h5>
                    <p>
                      With a vast assortment of gift cards from multiple brands,
                      we offer a world of options to suit every occasion and
                      celebration.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-layers" />
                  </div>
                  <div className="icon_box_content">
                    <h5>Instant Delivery</h5>
                    <p>
                      We understand the importance of timely gifting, and that's
                      why we offer instant delivery of e-gift cards through
                      email and other sources.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="icon_box icon_box_style4 box_shadow1">
                  <div className="icon">
                    <i className="ti-email" />
                  </div>
                  <div className="icon_box_content">
                    <h5>No Hidden Charges</h5>
                    <p>
                      Transparency is at the core of our values. When you choose
                      us, you can rest assured that there are no hidden charges
                      or additional fees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </>
    </>
  );
}

export default ContactUs;
