import React, { useEffect } from 'react'
import Testimonial from '../Components/Homepage/Testimonial'

function AboutUs() {
    useEffect(()=>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This provides a smooth scroll effect
          });
    },[])
  return (
    <>
    <>
  <div className="section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-4">
          <div className="about_img scene mb-4 mb-lg-0">
            <img src="../assets/CR%20Logo.png" alt="about_img" />
          </div>
        </div>
        <div className="col-lg-8">
          <div className="heading_s1">
            <h2>About Credence Rewards</h2>
          </div>
          <p>
            We believe that there’s a perfect gift for everyone, which is why we
            offer you gift cards from 100+ reputed brands for every gifting
            occasion. Gift your loved ones the freedom of choice to select a
            product they truly desire.
          </p>
          <p>
            For your last-minute gifting needs, we have e-gift cards from the
            leading brands in the country. Gift cards and e-gift cards also give
            the recipients the freedom to shop at an outlet close to their home
            or office. Experience new-age gifting with Credence Rewards
            <br />
            <span className="fw-bold">
              -India's most powerful gifting platform.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
  {/* END SECTION ABOUT */}
  {/* START SECTION WHY CHOOSE */}
  {/* <div className="section bg_light_blue2 pb_70">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 col-md-8">
          <div className="heading_s1 text-center">
            <h2>Why Choose Us?</h2>
          </div>
          <p className="text-center leads">
            For the past 7 years, At Credence Rewards, we take immense pride in
            being your trusted one-stop destination for a diverse selection of
            gift cards from multiple renowned brands.
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-pencil-alt" />
            </div>
            <div className="icon_box_content">
              <h5>Vast Range of Gift Card</h5>
              <p>
                With a vast assortment of gift cards from multiple brands, we
                offer a world of options to suit every occasion and celebration.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-layers" />
            </div>
            <div className="icon_box_content">
              <h5>Instant Delivery</h5>
              <p>
                We understand the importance of timely gifting, and that's why
                we offer instant delivery of e-gift cards through email and
                other sources.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="icon_box icon_box_style4 box_shadow1">
            <div className="icon">
              <i className="ti-email" />
            </div>
            <div className="icon_box_content">
              <h5>No Hidden Charges</h5>
              <p>
                Transparency is at the core of our values. When you choose us,
                you can rest assured that there are no hidden charges or
                additional fees.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* END SECTION WHY CHOOSE */}
  {/* START SECTION TEAM */}
  <div className="section pt-0">
    <div className="container">
      <div className="row">
        <div className="col-md-12 pb-5 mb-3">
          <div className="heading_s1 mb-3 mb-md-5 mt-4 py-3 text-center">
            <h3>🎁 Spreading Joy Through the Gift of Choice 🌟</h3>
            <p>
              Welcome to Credence Rewards, your trusted destination for
              delightful gift cards in India. With a legacy of 7 years, we have
              been passionately dedicated to making gifting moments more special
              and memorable for both businesses and customers.
            </p>
          </div>
          <div id="accordion" className="accordion accordion_style1">
            <div className="card">
              <div className="card-header" id="headingOne">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    🏆 Our Mission:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseOne"
                className="collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordion"
                style={{}}
              >
                <div className="card-body">
                  <p>
                    At Credence Rewards, our mission is simple yet profound: to
                    spread joy through the gift of choice. We believe that every
                    gift should be a reflection of thoughtfulness and
                    personalization, allowing recipients to select the perfect
                    present that brings them joy.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingTwo">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    🌟 The Power of Choice:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Gift cards empower both businesses and individuals to
                    express their appreciation, gratitude, and love. With our
                    diverse collection of gift cards from multiple esteemed
                    brands, we offer a world of options, ensuring that each
                    recipient receives a gift that resonates with their unique
                    preferences.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingThree">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    🏢 For Businesses:
                  </a>
                </h6>
              </div>
              <div
                id="collapseThree"
                className="collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    As a business-focused gifting solutions provider, we
                    understand the importance of strengthening connections with
                    customers and employees. Our tailored corporate gifting
                    solutions allow businesses to elevate their brand presence,
                    reward loyalty, and foster meaningful relationships.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFour">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    👫 For Individuals:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseFour"
                className="collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    For individuals seeking the perfect present for their loved
                    ones, our gift cards become the messenger of sentiments.
                    From birthdays to festivals, anniversaries to achievements,
                    our gift cards offer a memorable way to celebrate every
                    occasion.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFive">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    🛍️ The Joy of Gifting:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapseFive"
                className="collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    We take immense pride in being part of countless
                    celebrations and heartfelt moments. Our gift cards have
                    witnessed smiles, surprises, and unforgettable memories
                    shared between loved ones, making us an integral part of
                    their joyous journey.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFive">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapse6"
                  >
                    🌐 Convenience and Transparency:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse6"
                className="collapse"
                aria-labelledby="heading6"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Transparency and convenience are the pillars of our
                    services. We believe in offering an effortless gifting
                    experience, ensuring no hidden charges or complexities. With
                    instant delivery options, your heartfelt gift reaches its
                    destination promptly, adding joy to every celebration.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFive">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapse7"
                  >
                    💼 Committed to Excellence:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse7"
                className="collapse"
                aria-labelledby="heading7"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Our team of passionate professionals is committed to
                    excellence in everything we do. From curating a wide range
                    of gift cards to providing exceptional customer support, we
                    aim to exceed your expectations and make your gifting
                    journey seamless.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="headingFive">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapse8"
                  >
                    🙏 Gratitude:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse8"
                className="collapse"
                aria-labelledby="heading8"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    As we celebrate 7 years of touching lives and creating
                    smiles, we extend our heartfelt gratitude to our valued
                    clients, partners, and customers. Your unwavering support
                    and trust have been our driving force, inspiring us to
                    constantly innovate and improve our services.
                  </p>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="heading9">
                <h6 className="mb-0">
                  {" "}
                  <a
                    className="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapse9"
                  >
                    🎁 Join the Gifting Journey:
                  </a>{" "}
                </h6>
              </div>
              <div
                id="collapse9"
                className="collapse"
                aria-labelledby="collapse9"
                data-bs-parent="#accordion"
              >
                <div className="card-body">
                  <p>
                    Whether you are a business seeking corporate gifting
                    solutions or an individual looking to make someone's day
                    brighter, we welcome you to join us on our joyous gifting
                    journey. Together, let's make every occasion more special
                    and spread the joy of giving.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* END SECTION TEAM */}
  {/* START SECTION TESTIMONIAL */}
  <Testimonial/>
  {/* END SECTION TESTIMONIAL */}
  {/* START SECTION SHOP INFO */}
  {/* <div className="section pb_70">
    <div className="container">
      <div className="row g-0 border py-5 bg-light">
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-shipped" />
            </div>
            <div className="icon_box_content">
              <h5>Instant Delivery</h5>
              <p>
                We understand the importance of timely gifting, and that's why
                we offer instant delivery.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-money-back" />
            </div>
            <div className="icon_box_content">
              <h5>No Hidden Charges</h5>
              <p>
                Transparency is at the core of our values. So, there are no
                hidden charges or additional fees.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="icon_box icon_box_style1">
            <div className="icon">
              <i className="flaticon-support" />
            </div>
            <div className="icon_box_content">
              <h5>24/7 Support</h5>
              <p>
                We are available 24 X 7, for your service and help. We are
                serving 2 Cr plus users.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
</>

    </>
  )
}

export default AboutUs