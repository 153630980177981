import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getAllVouchers, getOrderDetails } from "../../services/Apiservices";
import SubscribeSection from "../Homepage/SubscribeSection";
import ReactPaginate from 'react-paginate';


function OrderDetails() {
  const { orderId } = useParams();
  const [orderDetail, setOrderDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderList, setOrderList] = useState(null);
  const[pageCount,setPageCount]=useState()
  const[currentPage,setCurrentPage]=useState(1)


  useEffect(() => {
    getCategorizedData();
    getOrders();

    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, [orderId]);

  const getCategorizedData = async () => {
    setLoading(true);
    try {
      let response = await getOrderDetails(orderId);
      if (response?.status == 200) {
        setOrderDetail(response?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      setOrderDetail("");
    }
  };
  

  

  const getOrders = async () => {
    setLoading(true);
    try {
      let response = await getAllVouchers(orderId,currentPage*12);
      if (response?.status == 200) {
        setOrderList(response?.data);
        setLoading(false);
        setPageCount( Math.floor(response?.data?.count / 12))

      }
    } catch (error) {
      setLoading(false);

      setOrderList(null);
    }
  };
 
  const handleShowStatus = (key) => {
    switch (key) {
      case 1:
        return "Not Started";
      case 2:
        return "Processing";
      case 3:
        return "Completed";
      case 4:
        return "Failed";
      case 5:
        return "Incompleted";

      default:
        return "N/A";
    }
  };

  return (
    <>
      <div className="main_content mt-3">
        {/* START SECTION SHOP */}
        <div className="section ">
          <div className="container mt-1">
            <h3 className="text-center mb-4">Order Details</h3>
            <div className="row">
              <div className="col-md-5">
                {/* Customer Details */}
                <div className="card mb-4">
                  <div className="card-header">Customer Information</div>
                  <div className="card-body">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-4 py-1">
                          <span className="price text-dark"> Name :</span>
                        </div>
                        <div className="col-8">{orderDetail?.name}</div>
                        <div className="col-4 py-1">
                          <span className="price text-dark"> Email :</span>
                        </div>
                        <div className="col-8">{orderDetail?.email}</div>
                        <div className="col-4 py-1">
                          <span className="price text-dark"> Mobile No :</span>
                        </div>
                        <div className="col-8">{orderDetail?.mobile_no}</div>
                        {/* <div className="col-4 py-1">
                    <span className="price text-dark"> Address :</span>
                  </div>
                  <div className="col-8">UserAddress object (1262)</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                {/* Order Summary */}
                <div className="card mb-4">
                  <div className="card-header">Order Summary </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-2 py-1">
                        <span className="price text-dark">Order Id:</span>
                      </div>
                      <div className="col-3">{orderDetail?.id}</div>
                      <div className="col-4 py-1">
                        <span className="price text-dark">Sub Total:</span>
                      </div>
                      <div className="col-3">₹ {orderDetail?.amount}</div>
                    </div>
                    <div className="row">
                      <div className="col-2 py-2">
                        <span className="price text-dark">Quantity:</span>
                      </div>
                      <div className="col-3">{orderDetail?.quantity}</div>
                      <div className="col-4 py-1">
                        <span className="price text-dark">Denomination:</span>
                      </div>
                      <div className="col-3">₹ {orderDetail?.denomination}</div>
                    </div>
                    <div className="row">
                      <div className="col-2 py-1">
                        <span className="price text-dark">Status:</span>
                      </div>
                      <div className="col-3">
                        <span className="">
                          <small>
                            {handleShowStatus(orderDetail?.order_status)}
                          </small>
                        </span>
                      </div>
                      <div className="col-4 py-2">
                        <span className="price text-dark">Email:</span>
                      </div>
                      <div className="col-3">
                        <span className="">
                          <small>
                            {orderDetail?.email_sent ? "Sent" : "Not Sent"}
                          </small>
                        </span>
                      </div>
                    </div>
                    <div className="row bg-light">
                      <div className="col-2 py-1">
                        <span className="price text-dark">Discount:</span>
                      </div>
                      <div className="col-3">
                        {orderDetail?.discount == 0 ? "Not Applied" : "Applied"}
                      </div>
                      <div className="col-4 py-1">
                        <span className="price text-dark">Total Amount:</span>
                      </div>
                      <div className="col-3">
                        ₹ {orderDetail?.denomination * orderDetail?.quantity}
                      </div>
                    </div>
                    <div className="row pt-2 border-top">
                      <div className="col-3 py-1">
                        <span className="price text-dark">Created At:</span>
                      </div>
                      <div className="col-9">{orderDetail?.created_on}</div>
                    </div>
                    <div className="row pt-2">
                      <div className="col-3 ">
                        <span className="price text-dark">Brand:</span>
                      </div>
                      <div className="col-9 text-primary">
                        {orderDetail?.provider_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Order Items */}
            <div className="card mb-5">
              <div className="card-header">Order Items</div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th>Voucher Id</th>
                        <th>Code</th>
                        <th>Pin</th>
                        <th>
                          <small>Card Value</small>
                        </th>
                        <th>Expiry</th>
                        <th>Date</th>
                        {/* <th>Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                     
                        {
                          orderList?.results?.length!==0 ?<>
                          {
                            orderList?.results?.map((item,index)=>{
                              return(
                                <tr key={index}>
                                <td>{item?.voucher_id}</td>
                                <td>{item?.code}</td>
                                <td>{item?.pin}</td>
                                <td>₹{item?.amount}</td>
                                <td>{item?.expiry}</td>
                                <td>{item?.created_on}</td>
                                <td>{item?.expiry}</td>



                                </tr>
                              )
                            })
                          }
                          </>: <td colSpan={7} className="text-muted">
                          oh! no, there is no voucher in this order.
                        </td>
                        }
                       
                    
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {
                      orderList?.count>12 && 
                      <div className="row">
                      <div className="col-12 mt-2 mt-md-4 ">
                        
                       <ReactPaginate
                       className="pagination pagination_style1 justify-content-center"
                
                       previousLabel="Prev"
                breakLabel="..."
                pageCount={pageCount}
                onPageChange={({ selected }) => setCurrentPage(selected+1)}
                containerClassName="pagination"
                activeClassName="active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextClassName="page-link"
              />
                       
                      </div>
                    </div>
                    }
   
          </div>
        </div>
        {/* END SECTION SHOP */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default OrderDetails;
