import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginShow } from "../redux/reducers/modalSlice";

function Footer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);

  const handleNavigate = (link) => {
    if (isLoggedIn) {
      navigate(link);
    } else {
      dispatch(loginShow());
    }
  };

  return (
    <>
      <footer className="footer_dark">
        <div className="pt-4">
          <div className="w-full">
            <div className="d-flex  justify-content-start  align-items-start gap-4 ">
              <div
                className="d-flex flex-column align-items-center"
                style={{ width: "50%" }}
              >
                <div className="d-flex flex-column align-items-center">
                  <div className=" text-center mx-auto">
                    <Link to="/">
                      <img
                        src="../../../assets/CR%20Logo.png"
                        style={{ width: "55%" }}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <p style={{ width: "60%" }} className="">
                    We believe that there’s a perfect gift for everyone. Gift
                    your loved ones the freedom of choice to select a product
                    they truly desire.
                  </p>
                </div>
                <div>
                  <ul className="social_icons social_white d-flex justify-content-center">
                    <li>
                      <a
                        href="https://www.facebook.com/people/Credence-Rewards/100076246998226/"
                        target="_blank"
                      >
                        <i className="ion-social-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/CredenceRewards"
                        target="_blank"
                      >
                        <i className="ion-social-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/credencerewards?originalSubdomain=in"
                        target="_blank"
                      >
                        <i className="ion-social-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/credencerewards/"
                        target="_blank"
                      >
                        <i className="ion-social-instagram-outline" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="widget">
                  <h6 className="widget_title">Policy & Info</h6>
                  <ul className="widget_links">
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms-&-conditions">Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="">
                <div className="widget">
                  <h6 className="widget_title">Category</h6>
                  <ul className="widget_links">
                    <li>
                      <Link to="/brand/35/category/Men">Men</Link>
                    </li>
                    <li>
                      <Link to="/brand/36/category/Women">Women</Link>
                    </li>
                    <li>
                      <Link to="/brand/2/category/E-Commerce">E-Commerce</Link>
                    </li>
                    <li>
                      <Link to="/brand/3/category/Fashion&Style">
                        Fashion &amp; Lifestyle
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="">
                <div className="widget">
                  <h6 className="widget_title">Contact us</h6>
                  <ul className="contact_info contact_info_light">
                    <li>
                      <Link to="/contact-us">Get in touch</Link>
                    </li>
                    {/* <li>
                      <i className="ti-location-pin"></i>
                      <p>
                        Credence Rewards Private Limited,
                        <br />
                        C/ 506 Neelkanth Business Park, Nathani Road, Vidyavihar
                        West, Mumbai Maharashtra 400086 India.
                      </p>
                    </li> */}
                    <li className="d-flex align-items-center ">
                      <div>
                        <i className="ti-email" />
                      </div>
                      <a href="mailto:support@credencerewards.com?subject=Issue related to order on Credence Rewards.com">
                        <span style={{ fontSize: "14px" }}>
                          support@credencerewards.com
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_footer border-top-tran">
          <div className="">
            <div className="row">
              <div className="col-md-12 align-items-center">
                <p className="mb-md-0 text-center ">
                  © All Rights Reserved by CredenceReward Pvt LTD 2024
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* END FOOTER */}
      <a href="#" className="scrollup" style={{ display: "none" }}>
        <i className="ion-ios-arrow-up" />
      </a>
    </>
  );
}

export default Footer;
