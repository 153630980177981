import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ProductList from './ProductList';
import { toast } from 'react-toastify';
import showNotification from '../services/NotificationService';
import { checkCrCode } from '../services/Apiservices';

function RedeemVocher() {
    const [code,setCode]=useState("")
    const[data,setData]=useState()
    const [show,setshow]=useState(false)
    const[isLoading,setIsLoading]=useState(false)
  
    const handleAPICall = () => {

        // Replace with your actual code
    if(code!=""){
        setIsLoading(true)
        checkCrCode(code)
        .then((response) => {
          // Handle the API response here
          if(response?.data?.data){
            setData(response?.data)
            setshow(true)

          } else{
         showNotification("danger",response?.data?.message)
                
          }
          setIsLoading(false)
        })
        .catch((error) => {
          // Handle any errors here
          console.error('API Error:', error);
        });
    }
        // Make the GET request using Axios
      
      };
  return (
    <>
    {
        show?<ProductList list={data}/> :   <div className="section pt-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12  py-5 mt-5">
              <div className="heading_s1 py-3 text-center">
                <h2>Enter Code to redeem your Voucher</h2>
              </div>
              <div className="field_form d-flex justify-content-center">
                <form
                 
                  id="redeem_code-form"
                  className="col-md-12 col-lg-6 py-2"
                  noValidate="novalidate"
                >
                  <input
                    type="hidden"
                    name="csrfmiddlewaretoken"
                    defaultValue="RiGHDeXLuTBPPBfeVcQtHwATeI3Al9DVZ9sMDREbfMODGzEZM13HQCuyoNdrhfYi"
                  />
                  <div className="row d-flex justify-content-center">
                    <div className="form-group col-md-12 mb-3">
                      <input
                        className="form-control"
                        minLength={15}
                        maxLength={16}
                        name="credence_code"
                        defaultValue=""
                        placeholder="Enter Credence Code"
                        id="credence_code"
                        autoComplete="off"
                        type="text"
                    onChange={(e)=>setCode(e.target.value)}
      
                      />
                    </div>
                    <div className="col-md-12 mb-3 d-flex justify-content-center">
                      <button
                        type="button"
                        title="Check Code!"
                        className="btn btn-fill-out"
                        id="submitButton"
                        name="submit"
                        onClick={()=>handleAPICall()}
                        
                      >
                        Redeem
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-0">
            <p className="leads text-center">How to redeem ?</p>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <i className="flaticon-shipped" />
                </div>
                <div className="icon_box_content">
                  <h5>Enter Code</h5>
                  <p>Enter the voucher code &amp; click Redeem button.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <i className="flaticon-money-back" />
                </div>
                <div className="icon_box_content">
                  <h5>Select Brand</h5>
                  <p>Select a brand available for your cr-code</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <i className="flaticon-support" />
                </div>
                <div className="icon_box_content">
                  <h5>Enter Basic Details</h5>
                  <p>Enter Name, email, Mobile to get your selected voucher.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-0 border p-5 text-center bg-light">
            <h3>Contact Us :</h3>
            <p className="mb-0 pb-0">
              If you have questions or facing any issue during redeeming your code,
              please direct your inquiry to CredenceRewards.com via email at{" "}
              <a
                className="border-bottom border-danger"
                href="mailto:support@credencerewards.com"
              >
                support@credencerewards.com{" "}
              </a>
              or choose below options to get quick support.
            </p>
            <p className="col-lg-12 my-2 px-5 py-2 d-flex justify-content-between">
              <a
                href="https://api.whatsapp.com/send?phone=917977276921&text=Hello Credence Rewards admin, I Have a Business"
                onClick="return confirm('Go to whatsapp')"
              >
                <i className="ion-social-whatsapp-outline" />
                <span>Chat Directly</span>
              </a>
              <a href="mailto:support@credencerewards.com?subject=Issue related to order on Credence Rewards.com">
                <i className="linearicons-envelope-open" /> <span>Write Us </span>
              </a>
            </p>
            <p className="my-0 py-0">
              By using CredenceRewards, you acknowledge that you have read,
              understood, and agreed to our Terms. Thank you for choosing Credence
              Rewards as your preferred gift card provider.
            </p>
          </div>
        </div>
      </div>
    }
 
{isLoading &&    <div className="preloader">
  <div className="lds-ellipsis">
    <span />
    <span />
    <span />
  </div>
</div> }

    </>
  )
}

export default RedeemVocher