import Login from "./Login";
import React, { useState } from "react";
import SubscribeSection from "../Components/Homepage/SubscribeSection";
import { useNavigate } from "react-router";
import { restrictAlpha } from "../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addMobile, googleLogin, sendOtp, verifyAddedNum, verifyOtp } from "../services/Apiservices";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setMobileNumber } from "../redux/reducers/mobileNumberSlice";
import showNotification from "../services/NotificationService";

import { login } from "../redux/reducers/authSlice";
import { setUser } from "../redux/reducers/userSlice";
import { setSession } from "../session";
import { loginHide, signUpShow } from "../redux/reducers/modalSlice";

function MobileNumberModal({showLogin,hideLogin}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setisLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const mobileNumber = useSelector((state) => state.mobileNumber);




  const validationSchema = Yup.object({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Mobile number must contain only digits")
      .min(10, "Mobile number must be at least 10 digits")
      .required("Mobile number is required"),
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      remember_me: false,
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleLogin(values);
    },
  });
  const handleLogin = async (values) => {
    setisLoading(true);
  
    try {
      let response = await addMobile(values?.mobile);
      if (response?.status == 200) {
        setisLoading(false);
        showNotification("success", "Enter otp sent to your mobile no.");
        dispatch(setMobileNumber(values?.mobile));
        setShowOtp(true);
        // navigate("/verify-otp")
      } else {
        setisLoading(false);
      }
    } catch (error) {
      setisLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const postData = new FormData();
      postData.append("access_token", credentialResponse.credential);

      const response = await googleLogin(postData);
      if (response.status === 200) {
        dispatch(loginHide())
        showNotification("success","Login Successfully")
        dispatch(login());
        dispatch(setUser(response?.data?.user));
        
        setSession(response?.data?.access);
        localStorage.setItem(
          "userDetails",
          JSON.stringify(response?.data?.user)
        );
      }
    } catch (error) {
      ;
    }
  };

  const otpvalidationSchema = Yup.object({
    otp: Yup.string()
      .required('OTP is required'),
 
  });

  const otpformik = useFormik({
    initialValues: {
      otp: '',
     
    },
    otpvalidationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleSubmitOtp(values)
    },
  });

  const handleSubmitOtp=async(values)=>{
    setisLoading(true)
    let body={
      "mobile_no":mobileNumber,
      "otp":values?.otp,
      
  
  }
    try {
      let response=await verifyAddedNum(body)
      if(response?.status==200){
        setisLoading(false)
hideLogin(false)
      
        dispatch(login());
        dispatch(setUser(response?.data?.details));
        setSession(response?.data?.access)
        localStorage.setItem('userDetails',JSON.stringify(response?.data?.details))
        // navigate("/")
        
      }else{
        setisLoading(false)
      }
    } catch (error) {
      setisLoading(false)
    }
  }
  const resendOtp=async()=>{
    setisLoading(true)
  

    try {
      let response=await addMobile(mobileNumber)
      if(response?.status==200){
      setisLoading(false)
        
      }else{
        setisLoading(false)
      }
    } catch (error) {
      setisLoading(false)

    }
  }
  return (
    <>
      <div
        className={
          showLogin
            ? "modal-backdrop  subscribe_popup show "
            : "modal fade subscribe_popup "
        }
        id="onload-popup"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered "
          role="document"
        >
          <div className="modal-content ">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                onClick={()=>hideLogin(false)}
              >
                <span aria-hidden="true">
                  <i className="ion-ios-close-empty" />
                </span>
              </button>
              <div className="row g-0 d-flex justify-content-center">
              
                <div className="col-sm-12 ">
                  <div className="popup_content ">
                    {showOtp ? (
                      <div className="login_wrap">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1 text-center">
                            <h3 className="d-inline">Verify OTP</h3>
                          </div>
                          <form
                            onSubmit={otpformik.handleSubmit}
                            className="mt-5 mb-5"
                            id="sign_up_form"
                          >
                            <div className="form-group mb-2 py-2 text-center">
                              <h5>
                                OTP Sent to <u>{mobileNumber}</u>{" "}
                                <Link
                                onClick={()=>setShowOtp(false)}
                                  
                                  title="Change Mobile No"
                                  style={{ padding: 3 }}
                                >
                                  <span className="ti-pencil" />
                                </Link>
                              </h5>
                            </div>
                            <div className="form-group mb-1 py-3">
                              <input
                                maxLength={6}
                                minLength={6}
                                name="otp"
                                defaultValue=""
                                placeholder="Enter OTP"
                                id="mobile_otp"
                                autoComplete="off"
                                onKeyPress={restrictAlpha}
                                type="text"
                                required=""
                                className="form-control"
                                onChange={otpformik.handleChange}
                                onBlur={otpformik.handleBlur}
                                value={otpformik.values.otp}
                              />
                              {otpformik.touched.otp && otpformik.errors.otp ? (
                                <div className="text-danger">
                                  {otpformik.errors.otp}
                                </div>
                              ) : null}
                            </div>
                            <div className="form-note mt-0 my-3">
                              Issue in OTP?
                              <Link to="" className="" onClick={resendOtp}>
                                Resend OTP
                              </Link>
                            </div>
                            <div className="form-group mb-3">
                              <button
                                type="submit"
                                className="btn btn-fill-out btn-block"
                                name="login"
                              >
                                Verify OTP
                              </button>
                            </div>
                          </form>
                          
                          {/* <div className="form-note text-center">
                            Don't Have an Account?{" "}
                            <Link to="/signup/">Sign up now</Link>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="login_wrap">
                        <div className="padding_eight_all bg-white">
                          <div className="heading_s1 text-center">
                            <h3>Add Mobile Number</h3>
                          </div>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="form-group mb-3">
                              <input
                                maxLength={15}
                                name="mobile"
                                defaultValue=""
                                placeholder="Mobile No"
                                id="mobile"
                                autoComplete="off"
                                type="text"
                                required=""
                                className="form-control"
                                onKeyPress={restrictAlpha}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.mobile}
                              />
                              {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="text-danger">
                                  {formik.errors.mobile}
                                </div>
                              ) : null}
                            </div>
                           
                            <div className="form-group mb-3">
                              <button
                                type="submit"
                                className="btn btn-fill-out btn-block"
                                name="login"
                              >
                                Submit
                              </button>
                            </div>
                           
                          </form>
              
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileNumberModal;
