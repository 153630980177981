import React from 'react'
import SubscribeSection from '../Homepage/SubscribeSection'

function MyRedeemOrders() {
  return (
    <>
    <div className="main_content mt-3">
  {/* START SECTION SHOP */}
  <div className="section py-3 my-3" style={{ minHeight: "60vh" }}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header ">
              <h3 className="d-inline">My Orders </h3>
              <span
                className="d-inline border px-3 bg-white"
                style={{ float: "right" }}
              >
                <div>
                  <span className="text-dark price">Complete</span> : 0
                </div>
                <div>
                  <span className="text-dark price">In-Complete</span> : 0
                </div>
              </span>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Order</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>
                        <small>Card Value</small>
                      </th>
                      <th>Brand</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-12 mt-2 mt-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* END SECTION SHOP */}
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
  <SubscribeSection/>
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
</div>

    </>
  )
}

export default MyRedeemOrders