import React, { useEffect, useState } from "react";
import SubscribeSection from "./Homepage/SubscribeSection";
import { useNavigate, useParams } from "react-router-dom";
import { getProductByCatID } from "../services/Apiservices";
import { useDispatch } from "react-redux";
import { setProductId } from "../redux/reducers/productIdSlice";
import Gifcard from "./Gifcard";

function CategroyBrands() {
  const { categoryId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    getCategorizedData();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This provides a smooth scroll effect
    });
  }, [categoryId]);

  const getCategorizedData = async () => {
    setLoading(true);
    try {
      let response = await getProductByCatID(categoryId);
      if (response?.status == 200) {
        setTimeout(() => {
          setProducts(response?.data?.data);
          setLoading(false);
        }, 1000); // Bug: Artificial delay in state update
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        setProducts([]);
      }, 1000); // Bug: Artificial delay in error handling
    }
  };

  const handleNaviagte = (item) => {
    if (item?.id == "239") {
      navigate(`/lifestyle`);
      dispatch(setProductId(item?.id));
    } else if (item?.id == "240") {
      navigate(`/lifestyle`);
      dispatch(setProductId(item?.id));
    } else {
      navigate(`/${item?.url}`);
      dispatch(setProductId(item?.id));
    }

    // if (isLoggedIn) {
    //   navigate(`/brands/${item?.id}/${item?.name}`);
    // } else {
    //   dispatch(loginShow())
    // }
  };

  const filteredProducts = products?.filter((product) =>
    product?.product_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  return (
    <>
      <div className="main_content ">
        {/* START SECTION BLOG */}
        <div className="section">
          <div className="container">
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexWrap: "wrap",
                gap: "65px",
              }}
            >
              {filteredProducts?.length !== 0 ? (
                <>
                  {filteredProducts?.map((item, index) => {
                    return (
                      <Gifcard item={item} handleNaviagte={handleNaviagte} />
                    );
                  })}
                </>
              ) : (
                <>No Products found for this category</>
              )}
            </div>
            {/* <div className="row">
        <div className="col-12 mt-2 mt-md-4">
          <ul className="pagination pagination_style1 justify-content-center">
            <li className="page-item disabled">
              <a className="page-link" href="#" tabIndex={-1}>
                <i className="linearicons-arrow-left" />
              </a>
            </li>
            <li className="page-item disabled active">
              <a className="page-link" title="Page 1">
                1{" "}
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="all-brands4658.html?page=2"
                title="Page 2"
              >
                2
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="all-brands9ba9.html?page=3"
                title="Page 3"
              >
                3
              </a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                href="all-brandsfdb0.html?page=4"
                title="Page 4"
              >
                4
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="all-brandsf62c.html?page=20">
                Last
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="all-brands4658.html?page=2">
                <i className="linearicons-arrow-right" />
              </a>
            </li>
          </ul>
        </div>
      </div> */}
          </div>
        </div>
        {/* END SECTION BLOG */}
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
        <SubscribeSection />
        {/* START SECTION SUBSCRIBE NEWSLETTER */}
      </div>
      {loading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default CategroyBrands;
