import React, { useState } from 'react'
import SubscribeSection from '../Components/Homepage/SubscribeSection'
import { useNavigate } from 'react-router'
import { restrictAlpha } from '../constants'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sendOtp } from '../services/Apiservices';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMobileNumber } from '../redux/reducers/mobileNumberSlice';
import showNotification from '../services/NotificationService';





function Login() {
  const navigate=useNavigate()
  const dispatch=useDispatch()
  const [loading,setisLoading]=useState(false)


  
  const validationSchema = Yup.object({
  
    mobile: Yup.string()
      .matches(/^[0-9]+$/, 'Mobile number must contain only digits')
      .min(10, 'Mobile number must be at least 10 digits')
      .required('Mobile number is required'),
  
  });

  const formik = useFormik({
    initialValues: {
     
      mobile: '',
      remember_me:false
    
    },
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleLogin(values)
    },
  });
  const handleLogin=async(values)=>{
    setisLoading(true)
    let body={
      "mobile":values?.mobile,
      
  }
    try {
      let response=await sendOtp(body)
      if(response?.status==200){
        setisLoading(false)
        showNotification("success","Enter otp sent to your mobile no.")
        dispatch(setMobileNumber(values?.mobile));

        navigate("/verify-otp")
        
      }else{
        setisLoading(false)
      }
    } catch (error) {
      setisLoading(false)
    }
  }

  return (
<>
<div className="main_content ">
  {/* START LOGIN SECTION */}
  <div className="login_register_wrap section">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-md-10 my-5">
          <div className="login_wrap">
            <div className="padding_eight_all bg-white">
              <div className="heading_s1 text-center">
                <h3>Login</h3>
              </div>
              <form
                onSubmit={formik.handleSubmit}
               
              >
                <div className="form-group mb-3">
                  <input
                    maxLength={15}
                    name="mobile"
                    defaultValue=""
                    placeholder="Mobile No"
                    id="mobile"
                    autoComplete="off"
                    type="text"
                    required=""
                    className="form-control"
                    onKeyPress={restrictAlpha}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                  />
                           {formik.touched.mobile && formik.errors.mobile ? (
          <div className="text-danger">{formik.errors.mobile}</div>
        ) : null}
                </div>
                <div className="login_footer form-group mb-3">
                  <div className="chek-form">
                    <div className="custome-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="remember_me"
                        id="remember_me"
                        defaultValue=""
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.remember_me}
                      />
                      <label className="form-check-label" htmlFor="remember_me">
                        <span>Remember me</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                <button
                    type="submit"
                    className="btn btn-fill-out btn-block"
                    name="login"
                    
                  >
                    Send OTP
                  </button>
                </div>
                <div className='text-center mb-2'>OR</div>
        <div className='col-md-6 text-center'>
       
        </div>

              </form>
              <div className="different_login">
                <span> </span>
              </div>
              <div className="form-note text-center">
                Don't Have an Account?{" "}
                <Link to="/signup">Sign up now</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* END LOGIN SECTION */}
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
 <SubscribeSection/>
  {/* START SECTION SUBSCRIBE NEWSLETTER */}
</div>
{
  loading &&     <div className="preloader">
  <div className="lds-ellipsis">
    <span />
    <span />
    <span />
  </div>
</div> 
}
</>

  )
}

export default Login