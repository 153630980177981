import * as api from "../request";

export const checkCrCode = async (code) => {
    return await api.GetReq(`/proxy/api/check-cr-code?credence_code=${code}`);
  };
  export const  register = async (body) => {
    return await api.PostReq(`/api/auth/user/auth/register`,body);
  };
  export const  verifyOtp = async (body) => {
    return await api.PostReq(`/api/auth/verify/auth/verify-otp`,body);
  };
  
  export const  sendOtp = async (body) => {
    return await api.PostReq(`/api/auth/send/auth/send-otp`,body);
  };

  export const getProductByCatID = async (id) => {
    return await api.GetReq(`/api/brands/api/category-product/?cat_id=${id}`);
  };
  

  export const getProductDetail = async (id) => {
    return await api.GetReq(`/api/brands/product/${id}/details`);
  };
  export const checkCouponCode = async (code,id) => {
    return await api.GetReq(`/api/brands/check-coupon-code?code=${code}&brand_id=${id}`);
  };
  export const  createOrder= async (body) => {
    return await api.PostReq(`/api/brands/create-order`,body);
  };
  export const  checkBalance= async (body) => {
    return await api.PostReq(`/api/brands/proxy/check-balance`,body);
  };

  export const getAllOrders = async (offset) => {
    return await api.GetReq(`/api/brands/my-orders?limit=12&offset=${offset==12?"":offset}`);
  };
  export const getOrderDetails = async (id) => {
    return await api.GetReq(`/api/brands/order/${id}/details`);
  };
  export const getAllVouchers = async (orderId,offset) => {
    return await api.GetReq(`/api/brands/order/${orderId}/vouchers?limit=12&offset=${offset==12?"":offset}`);
  };

  export const getAllBrands = async (offset,search) => {
    return await api.GetReq(`/api/brands/products?limit=12&offset=${offset==12 || search?"":offset}&q=${search}`);
  };

  export const  getProfile= async () => {
    return await api.GetReq(`/api/auth/user-profile`);
  };
  export const  upDateProfile= async (body) => {
    return await api.PostReq(`/api/auth/user-profile`,body);
  };
  
  export const  addContactUs= async (body) => {
    return await api.PostReq(`/api/brands/api/contact-us`,body);
  };
  export const  addContactB2B= async (body) => {
    return await api.PostReq(`/api/brands/api/b-2-b/contact`,body);
  };
  export const  getHomepageProduct= async () => {
    return await api.GetReq(`/api/brands/home-page`);
  };

  export const  googleLogin= async (body) => {
    return await api.PostReq(`/api/auth/google/`,body);
  };

  export const  addSubscription= async (body) => {
    return await api.PostReq(`/api/brands/api/subscribe-news-feed`,body);
  };

  export const  addMobile= async (mobileno) => {
    return await api.GetReq(`/api/auth/add-mobile-social/?mobile_no=${mobileno}`);
  };
  

  export const  verifyAddedNum = async (body) => {
    return await api.PostReq(`/api/auth/add-mobile-social/`,body);
  };
  export const  globalSearchApi = async (search) => {
    return await api.GetReq(`/api/brands/search?query=${search}`);
  };
 
  export const  getOrderInvoiceId = async (id) => {
    return await api.getReq(`/api/brands/download-invoice?order_id=${id}`);
  };

  export const  getOrderReportId = async (id) => {
    return await api.getReq(`/api/brands/download-excel?order_id=${id}`);
  };